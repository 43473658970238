import Svg, { Path, Rect } from "react-native-svg";
import {colors} from "../../theme";

const CloseIcon = ({color}) => {
  return (
      <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <Rect width="20" height="20" rx="6" fill={color ?? colors.backgroundGray}/>
          <Path d="M4 4L16 16M4 16L16 4" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      </Svg>
  );
};

export default CloseIcon;
