import { StyleSheet } from "react-native";
import {colors} from "../../../../theme";

export default StyleSheet.create({
    title: {

    },
    logo: {
        flex: 1,
        height: 40,
        width: 90,
        alignSelf: "center",
        margin: 30
    },
    label: {
        fontSize: '14px',
        color: colors.primary,
        marginLeft: 25,
        marginTop: 5,
        lineHeight: 'normal',
        fontFamily: 'Fira Sans',
        fontStyle: 'normal',
        fontWeight: 'bold'
    },
    input: {
        backgroundColor: 'white',
        height: '40px',
        width:'261px',
        overflow: 'hidden',
        marginTop: 5,
        marginBottom: 7,
        borderRadius: '10px',
        border: '1px solid #5F33E1',
        marginHorizontal: '20px',
        paddingLeft: 16,
    },
    focusedInput: {
        borderRadius: '10px',
        border: '1px solid #5F33E1',
        backgroundColor: 'white',
    },
    button: {
        backgroundColor: colors.primary,
        marginHorizontal: '20px',
        marginTop: '10px',
        height: '40px',
        width:'268px',
        borderRadius: '10px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonTitle: {
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Fira Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
    },
    footerView: {
        flex: 1,
        alignItems: 'center',
        marginTop: 20
    },
    footerText: {
        fontSize: 16,
        color: '#2e2e2d'
    },
    footerLink: {
        color: "#788eec",
        fontWeight: "bold",
        fontSize: 16
    }
});
