import Svg, { Circle, Path, Rect } from "react-native-svg";
const StatusCompleted = ({}) => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <Rect width="20" height="20" rx="6" fill="#34C759"/>
            <Circle cx="10" cy="10" r="6.5" stroke="#1D8136"/>
            <Path d="M6 9.5L10 13L13.5 7.5" stroke="#1D8136" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );
};

export default StatusCompleted;
