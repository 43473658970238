import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { SettingsScreen } from "../../screens";

const SettingsStack = createStackNavigator();

const SettingsNavigation = () => {

    return (
        <SettingsStack.Navigator>
            <SettingsStack.Screen
                name="Settings"
                component={SettingsScreen}
                options={({ navigation }) => ({ headerShown: false, title: 'RicordaMe' })}
            />
        </SettingsStack.Navigator>
    );
};

export default SettingsNavigation;
