//React Modules
import React, {useEffect, useState} from "react";
import { mapPatientToOption } from "../../../../utils";

//Store
import useStore from "../../../../store";
import useAuthStore from "../../../../store/auth-store";

//components
import {FlatList, Text, View, ActivityIndicator} from 'react-native'
import ModalFilter from "../../../../components/ModalFilter";
import TaskCard from "../TaskCard/index";

//styles
import { styles }  from './styles'
import { colors, fonts } from "../../../../theme";

const TaskList = ({ flatListRef, filterVisible, setFilterVisible, triggerEditTask }) => {
    const tasks = useStore(state => state.tasks);
    const patients = useStore(state => state.patients);
    const loading = useStore(state => state.loading);

    const [tasksFiltered, setTasksFiltered] = useState(tasks);

    const filters = [
        {
            sortBy: 'pending',
            label: 'Programmato',
            group: 'status',
            handle: (data, valueSelected) => {
                return data.filter((task) => task.status.toLowerCase() === 'pending');
            }
        },
        {
            sortBy: 'missed',
            label: 'Non completato',
            group: 'status',
            handle: (data, valueSelected) => {
                return data.filter((task) => task.status.toLowerCase() === 'missed');
            }
        },
        {
            sortBy: 'completed',
            label: 'Completato',
            group: 'status',
            handle: (data, valueSelected) => {
                return data.filter((task) => task.status.toLowerCase() === 'completed');
            }
        },
        {
            sortBy: 'patients',
            label: 'Pazienti',
            group: 'patients',
            options: mapPatientToOption(patients),
            handle: (data, valueSelected) => {
                return data.filter((task) => task.patientID === valueSelected);
            }
        },
    ];

    useEffect(() => {
        setTasksFiltered(tasks)
    },[tasks]);

    const handleApply = (data) => {
        setTasksFiltered(data);
        setFilterVisible(false);
    }

    return (
        <View style={styles.listContainer}>
            { loading ? (
                    <View style={styles.loaderContainer}>
                        <ActivityIndicator size="large" color={colors.primary} />
                    </View>
                ) : (
                        tasks.length === 0 ? (
                            <View style={styles.noContentContainer}>
                                <Text style={styles.noContentText}>No tasks</Text>
                            </View>
                        ) : (
                            <FlatList
                                ref={flatListRef}
                                data={tasksFiltered}
                                renderItem={ ({item}) => <TaskCard task={item} triggerEditTask={triggerEditTask} patients={patients}/>}
                                showsVerticalScrollIndicator={false}
                            />
                        )
                    )
            }
            <ModalFilter
            open={filterVisible}
            setOpen={setFilterVisible}
            data={tasks}
            filters={filters}
            handleApply={handleApply}
            />
        </View>
    )
}

export default TaskList;
