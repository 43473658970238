import { StyleSheet } from "react-native";
import {colors} from "../../theme";

export default StyleSheet.create({
    buttonTitle: {
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Fira Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
    },
    button: {
        backgroundColor: colors.primary,
        border: `1px solid ${colors.primary}`,
        marginTop: '10px',
        height: '40px',
        width:'100%',
        borderRadius: '10px',
        alignItems: 'center',
        justifyContent: 'center'
    },
});
