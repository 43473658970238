import React, {useState} from "react";
import {View, TextInput, TouchableOpacity, Text} from "react-native";

import CustomModal from "../../../../components/customModal";

//Services
import Firebase from "../../../../service/Firebase";
import { sendPasswordResetEmail } from 'firebase/auth';

import WebAlert from "../../../../components/WebAlert";
import CloseIcon from "../../../../components/Svg/Close";

import {styles} from "./styles";

const ResetPassword = ({ open, setOpen }) => {
    const [email, setEmail] = useState('')
    const [alertModal, setAlertModal] = useState(false);
    const [alerInfo, setAlerInfo] = useState(null);

    const sendLink = () => {
        sendPasswordResetEmail(Firebase.auth, email).then(() => {
            setAlerInfo({
                title: 'Success',
                text: 'Reset link sent to your email',
            });
            setAlertModal(true);
            setOpen(false)
        }).catch((err) => {
            setAlerInfo({
                title: 'Error',
                text: err.message,
                error: true
            });
            setAlertModal(true);
        });
    }

    return (
        <>
            <CustomModal open={open} transparent={true} animationType="slide" type="custom">
                <View style={styles.modal}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.title}>Recupera la password</Text>
                        <TouchableOpacity onPress={() => setOpen(false)}>
                            <CloseIcon/>
                        </TouchableOpacity>
                    </View>
                    <Text style={styles.subtitle}>Inserisci la tua email e riceverai un link per il recupero.</Text>
                    <TextInput
                        placeholder="asempio@dominio.it"
                        placeholderTextColor="#aaaaaa"
                        onChangeText={(text) => setEmail(text)}
                        value={email}
                        underlineColorAndroid="transparent"
                        autoCapitalize="none"
                        style={styles.formInput}
                    />
                    <TouchableOpacity
                        style={styles.button}
                        onPress={() => sendLink()}>
                        <Text style={styles.buttonTitle}>INVIA</Text>
                    </TouchableOpacity>

                </View>
            </CustomModal>
            {  alertModal && alerInfo &&
                <WebAlert
                open={alertModal}
                setOpen={setAlertModal}
                title={alerInfo?.title}
                alertText={alerInfo?.text}
                error={alerInfo?.error}
                />
            }
        </>
    );
};

export default ResetPassword;
