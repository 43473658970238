//React modules
import { StyleSheet } from 'react-native'

//Libs
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

//Components
import SettingsNavigation from "./settings/settings";
import {PatientsScreen, TodoScreen} from '../screens'

import TaskIcon from "../components/Svg/TaskIcon";
import UserIcon from "../components/Svg/UserIcon";
import NutIcon from "../components/Svg/NutIcon";

import { colors, fonts } from '../theme'

const Tab = createBottomTabNavigator();

const TabsNavigator = ({ route }) => {
  return (
    <Tab.Navigator initialRouteName="Tasks" screenOptions={screenOptions}>
      <Tab.Screen name="Tasks" options={{ title: 'RicordaMe'}} component={TodoScreen} />
      <Tab.Screen name="Patients" options={{ title: 'RicordaMe'}} component={PatientsScreen} />
      <Tab.Screen name="Settings_" options={{ title: 'RicordaMe'}} component={SettingsNavigation} />
   </Tab.Navigator>
  )
}

const screenOptions = ({ route }) => ({
  tabBarIcon: ({ focused }) => {
    switch (route.name) {
      case 'Tasks':
        return (
          <TaskIcon focused={focused}/>
        )

      case 'Patients':
        return (
          <UserIcon focused={focused}/>
        )

      case 'Settings_':
        return (
            <NutIcon focused={focused}/>
        )

      default:
        break
    }
  },
  tabBarStyle: styles.tabBar,
  tabBarShowLabel: false,
  tabBarItemStyle: styles.tabBarItem,
  tabBarLabelStyle: styles.tabBarLabel,
  headerShown: false,
  tabBarActiveTintColor: colors.primary,
  tabBarInactiveTintColor: colors.textLight
})

const styles = StyleSheet.create({
  tabBar: {
    backgroundColor: colors.primaryLight,
    height: 80,
    borderTopWidth: 0,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    shadowOffset: {
      width: 0,
      height: 10
    },
    shadowColor: colors.shadow,
    shadowOpacity: 0.25,
    shadowRadius: 24,
    elevation: 5
  },
  tabBarItem: {
    marginVertical: 10
  },
  tabBarLabel: {
    fontFamily: fonts.bold,
    fontSize: fonts.xs
  },
  buttonIcon: {
    tintColor: colors.textLight,
    width: 32,
    height: 32
  }
})

export default TabsNavigator
