import { StyleSheet } from 'react-native'
import { colors, fonts } from '../../theme'

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'transparent',
        paddingTop: 20,
        paddingHorizontal: '15px',
        width: '100%',
    },
    item: {
        backgroundColor: colors.cardBg,
        borderRadius: 10,
        padding: 15,
        marginVertical: 8,
    },
    option:{
        flex: 1,
        flexDirection:'row',
        alignItems:'center',
    },
    optionTitle: {
        fontSize: fonts.sm,
        fontWeight: fonts.firaSans,
        color: colors.primary,
        marginHorizontal: 10,
    },
    optionSubtitle: {
        fontSize: fonts.xxs,
        fontWeight: fonts.bold,
        color: colors.text,
        marginHorizontal: 10,
    },
    sectionHeader: {
        fontSize: fonts.sm,
        fontWeight: fonts.regular,
        color: colors.text,
    },
    logoutBtn: {
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        height: '101px',
        width: '100%',
        backgroundColor: colors.white,
        paddingHorizontal: '31px',
    },
    logoutText: {
        fontSize: fonts.sm,
        fontWeight: fonts.firaSans,
        color: colors.red,
    }
})
