import React, { useState } from 'react'
import { Image, ImageBackground, Text, View } from 'react-native'

import EmailAuth from "./components/emailAuth";
import ResetPassword from "./components/resetPassword";

import styles from './styles'

export default function AuthScreen({ navigation }) {
    const [resetPassword, setResetPassword] = useState(false)

    const onFooterLinkPress = () => {
        setResetPassword(true)
    }

    return (
        <ImageBackground
            source={require('../../assets/bg-primary.png')}
            style={styles.backgroundImage}
        >
            <View style={styles.container}>
                    <Image
                        style={styles.logo}
                        source={require('../../../assets/icon.png')}
                    />
                <EmailAuth />
            </View>
            <View style={styles.footerView}>
                <Text style={styles.footerText}>
                    Hai dimenticato la password? {' '}
                    <Text onPress={onFooterLinkPress} style={styles.footerLink}>
                        Recuperala!
                    </Text>
                </Text>
            </View>
            <ResetPassword open={resetPassword} setOpen={setResetPassword} />
        </ImageBackground>
    );
}
