import Svg, { Circle, Path, Rect } from "react-native-svg";
import {View} from "react-native";
import { colors } from '../../theme';

const CircleBox = ({checked, style}) => {

    return (
        <View style={style}>
            <Svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <Circle cx="6" cy="6" r="5.5" stroke={ checked ? colors.primary : "black"} style={{position: 'relative'}}/>
            </Svg>
            {checked && <Svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"
                  style={{position: 'absolute', left: 3, top: 3}}>
                <Circle cx="3" cy="3" r="3" fill={colors.primary}/>
            </Svg>}
        </View>
    );
};

export default CircleBox;
