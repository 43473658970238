import React from "react";
import {months} from "../../../../constants";

import {styles} from "../TaskList/styles";

import {Text, View} from "react-native";
import ArrowRight from "../../../../components/Svg/ArrowRight";
import StatusProgress from "../../../../components/Svg/StatusProgress";
import StatusLate from "../../../../components/Svg/StatusLate";
import StatusCompleted from "../../../../components/Svg/StatusCompleted";
const TaskCardFooter = ({ reminder, orderedReminders, index, date, date2 }) => {

    const status = {
        pending: <StatusProgress/>,
        missed: <StatusLate/>,
        completed: <StatusCompleted/>
    }

    return (
        <View>
            { index > 0 && <Text style={[styles.reminderText, { marginVertical: '5px'}]}>{`Ricorrenza ${index + 1}`}</Text> }
            <View style={styles.section}>
                <View style={{ flex: '0 0 50%', flexDirection: 'row', alignItems: 'center'}}>
                    <View>
                        { status[reminder.days[0].status] }
                    </View>
                    <Text style={styles.reminderText}>{reminder.days[0]?.day}</Text>
                    {
                        (reminder.days[0]?.dates && date) && (
                            <Text style={styles.reminderText}>{`${date.getDate()} ${months[date.getMonth()]}`}</Text>
                        )
                    }
                </View>
                <View style={{ flex: '0 0 50%', flexDirection: 'row', alignItems: 'center'}}>
                    {
                        reminder?.days[1] && (
                            <View>
                                <View>
                                    <ArrowRight/>
                                </View>
                                <Text style={styles.reminderText}>{reminder?.days[1]?.day}</Text>
                                {
                                    (orderedReminders[0]?.days[1]?.dates && date2) && (
                                        <Text style={styles.reminderText}>{`${date2.getDate()} ${months[date2.getMonth()]}`}</Text>
                                    )
                                }
                            </View>
                        )
                    }
                </View>
            </View>
        </View>
    )
};

export default TaskCardFooter;
