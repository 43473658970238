import { StyleSheet } from 'react-native'
import { colors, fonts } from '../../theme'

export const styles = StyleSheet.create({
    container: {
        backgroundColor: 'transparent',
        width: '100%',
        paddingHorizontal: '20px',
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    modalTitle: {
        color: colors.black,
        fontFamily: fonts.firaSans,
        fontSize: fonts.sm,
        lineHeight: 'normal',
        marginBottom: '5px',
        marginLeft: '10px',
    },
    actionButton: {
        borderRadius: 5,
        padding: 10,
        width: '100%',
        alignItems: 'center',
        marginBottom: 25,
    },
    withBackground: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    normalBackground: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
})
