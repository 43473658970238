import React from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {getRemindersSorted} from "../../../../utils";

import Card from "../../../../components/Card/Card";
import StatusProgress from "../../../../components/Svg/StatusProgress";
import StatusLate from "../../../../components/Svg/StatusLate";
import StatusCompleted from "../../../../components/Svg/StatusCompleted";
import TaskCardFooter from './TaskCardFooter'

import {styles} from "../TaskList/styles";
import {colors, fonts} from "../../../../theme";
const TaskCard = ({ task, triggerEditTask, patients}) => {
    const orderedReminders = getRemindersSorted(task.reminders, task.range);
    const patient = patients.find(patient => patient.uid === task.patientID);

    const status = {
        pending: <StatusProgress/>,
        missed: <StatusLate/>,
        completed: <StatusCompleted/>
    }

    return (
        <TouchableOpacity onPress={() => triggerEditTask(task)}>
            <Card key={task.id}>
                <View section="header">
                    <View style={styles.section}>
                        <Text style={styles.title}>{`${patient.firstname} ${patient.lastname}`}</Text>
                        <View>
                            { status[task.status] }
                        </View>
                    </View>
                </View>

                <View section="body">
                    <Text style={{ color: colors.text, fontFamily: fonts.firaSans, fontSize: fonts.xs, marginBottom: 20 }}>{task.description}</Text>
                </View>

                <View section="footer">
                    {
                        orderedReminders.map((reminder, index) => {
                            const date = reminder?.days[0]?.dates && new Date(reminder?.days[0]?.dates[0]?.date);
                            const date2 = reminder?.days[1]?.dates && new Date(reminder?.days[1]?.dates[0]?.date);

                            return (
                                <TaskCardFooter
                                    key={reminder.id}
                                    reminder={reminder}
                                    orderedReminders={orderedReminders}
                                    index={index}
                                    date={date}
                                    date2={date2}
                                />
                            )
                        })
                    }
                </View>
            </Card>
        </TouchableOpacity>
    )
};

export default TaskCard;
