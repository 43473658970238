import {StyleSheet} from "react-native";
import {colors, fonts} from "../../theme";

export const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "space-between",
    },
    button: {
        width: '100%',
        height: 60,
        backgroundColor: colors.primary,
        borderRadius: 8,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
    },
    text: {
        fontSize: 16,
        color: colors.white,
        fontWeight: "bold",
    },
    header: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 8,
    },
    title: {
        color: colors.primary,
        fontFamily: fonts.semiBold,
        fontSize: fonts.xs,
        marginBottom: 8,
        marginLeft: '10px',
    },
    addBtn: {
        borderRadius: '10px',
        height: '40px',
        width: '100%',
        backgroundColor: colors.backgroundLight,
        border: '1px solid #1D8136',
        justifyContent: 'center',
        alignItems: 'center',
    },
    addBtnText: {
        fontFamily: fonts.firaSans,
        fontSize: fonts.xs,
        color: colors.green
    },

    // Modal styles
    fullScreen: {
        backgroundColor: colors.backgroundLight,
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '80%',
        padding: 24,
        elevation: 5
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 8,
        marginBottom: 4,
    },
    modalTitle: {
        color: colors.text,
        fontFamily: fonts.semiBold,
        fontSize: fonts.sm,
        marginBottom: 12
    },
    modalBody: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    modalFooter: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 24,
        borderTopWidth: 1,
        borderTopColor: colors.text
    },
    modalCancelBtnText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.primary
    },
    modalSaveBtnText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.primary
    },
    modalDeleteBtnText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.critical
    },

    input: {
        border: `1px solid ${colors.primary}`,
        borderColor: colors.primary,
        backgroundColor: 'white',
        height: '40px',
        overflow: 'hidden',
        borderRadius: 12,
        paddingLeft: 16,
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm,
        padding: 12,
        width: '100%'
    },
    buttonAttachment: {
        borderRadius: '10px',
        height: '40px',
        width: '100%',
        backgroundColor: colors.backgroundLight,
        border: '1px dotted #1D8136',
        justifyContent: 'center',
        alignItems: 'center',
    }
});
