// React modules
import React, {useEffect, useState} from "react";
import {mapPatientToOption, validPatients} from "../../../../utils";
import { initialStateTask } from "../../../../constants";

//store
import useAuthStore from "../../../../store/auth-store";
import useStore from "../../../../store";

// Components
import { ModalForm } from "../../../../components/";
import ReminderManager from "../ReminderManager";
import FilePicker from "../../../../components/FilePicker";
import WebAlert from "../../../../components/WebAlert";
import DatePicker from "../DatePicker";

import {useDropdown} from "../../../../hooks";
import { setDates, validRange } from "../../../../utils";

const TaskModal = ({ open, setOpen, taskToEdit = null, resetTaskToEdit }) => {
    const user = useAuthStore((state) => state.user);
    const patients = useStore((state) => state.patients);

    const [task, setTask] = useState(initialStateTask(user));
    const [reminders, setReminders] = useState([]);
    const [range, setRange] = useState({ startDate: undefined, endDate: undefined });

    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [alertInfo, setAlertInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const createTask = useStore((state) => state.createTask);
    const updateTask = useStore((state) => state.updateTask);
    const deleteTask = useStore((state) => state.deleteTask);

    const patientsDropdown = useDropdown(mapPatientToOption(validPatients(patients)));

    useEffect(() => {
        if (taskToEdit) {
            setTask({...taskToEdit});
            patientsDropdown.setDropdownValue(taskToEdit.patientID);
            setRange({
                startDate: taskToEdit.range.startDate !== null ?  new Date(taskToEdit.range.startDate) : null,
                endDate: taskToEdit.range.endDate !== null ? new Date(taskToEdit.range.endDate) : null
            });
            setReminders([...taskToEdit.reminders]);
        }
    },[taskToEdit]);

    const handleUpdateReminders = (updatedReminders) => {
        //setTask({...task, reminders: updatedReminders});
        setReminders(updatedReminders);
    };

    const handleUrlAttachment = (url) => {
        task.attachments.push(url)
    }

    const handleDeleteAttachment = async (file, files, setFiles, index, isFireStorage) => {
        const attachmentsUrl = [...task.attachments];
        const newFiles = [...files];
        attachmentsUrl.splice(index, 1);
        newFiles.splice(index, 1);
        setFiles(newFiles);
        task.attachments = attachmentsUrl;
        setTask({...task})
    };

    const handleCancel = () => {
        setTask(initialStateTask(user));
        patientsDropdown.setDropdownValue("");
        resetTaskToEdit();
        setOpen(false);
    };

    const handleErrors = () => {
        const errors = {
            Promemoria: task.description === "",
            Assistito: patientsDropdown.dropdownValue === "" || patientsDropdown.dropdownValue === null,
            Ricorrenza: reminders.length === 0,
        };

        const missingFields = Object.entries(errors)
            .filter(([field, hasError]) => hasError)
            .map(([field]) => field);

        setAlertInfo({
            title: "Completare tutti i campi",
            alertText: `Devi completare i seguenti campi: \n ${missingFields.join(", ")}`,
            error: true
        });

        if (missingFields.length > 0) {
            setShowErrorAlert(true);
            setIsLoading(false)
            return false;
        }

        return true;
    };

    const handleSave = async () => {
        setIsLoading(true);
        if (!handleErrors()) return;

        const patient = patients.find((patient) => patient.uid === patientsDropdown.dropdownValue);

        task.caregiver = user.displayName;
        task.patientName = `${patient.firstname} ${patient.lastname}`;
        task.patientID = patientsDropdown.dropdownValue;
        task.reminders = reminders;

        const rangeDate = {
            startDate: range.startDate ? range.startDate.toISOString() : null,
            endDate: range.endDate ? range.endDate.toISOString() : null
        }

        if (validRange(rangeDate)) {
            const remindersWithDates = setDates(reminders, rangeDate);
            task.range = rangeDate;
            task.reminders = remindersWithDates;
        }

        if (taskToEdit) {
            updateTask(task);
            setTask(initialStateTask(user));
            setIsLoading(false)
            resetTaskToEdit();
            setOpen(false);
            return;
        }

        createTask(user.uid, task);
        setIsLoading(false);
        setTask(initialStateTask(user));
        setOpen(false);
    };

    const handleDelete = () => {
        setAlertInfo({
            title: "Elimina task",
            alertText: "Conferma l’eliminazione",
            handleConfirm: async () => {
                deleteTask(taskToEdit.uid);
                setTask(initialStateTask(user));
                resetTaskToEdit();
                setOpen(false);
                setShowErrorAlert(false)
            },
            error: false
        });
        setShowErrorAlert(true);
    }

    const form = [
        {
            key: "task",
            label: "Promemoria",
            type: "textArea",
            placeholder: "Scrivi in dettaglio come il tuo assistito/a dovrebbe usare il promemoria...",
            value: task.description,
            handleChange: (value) => setTask({ ...task, description: value }),
        },
        {
            key: "patient",
            label: "Assistito",
            type: "dropdown",
            placeholder: "Nome Cognome",
            emptyDropdownText: "Nessun assistito attivo. \n Ricorda che ogni assistito deve confermare tramite la chat di Telegram l’attivazione del suo account.",
            dropdownOpen: patientsDropdown.dropdownOpen,
            setIsDropdownOpen: patientsDropdown.setIsDropdownOpen,
            dropdownValue: patientsDropdown.dropdownValue,
            setDropdownValue: patientsDropdown.setDropdownValue,
            items: patientsDropdown.items,
            setItems: patientsDropdown.setItems,
        },
        {
            key: "range",
            label: "Validità",
            customComponent: <DatePicker range={range} setRange={setRange}/>
        },
        {
            key: "reminders",
            label: "Ricorrenza",
            customComponent: <ReminderManager taskReminders={reminders} updateTaskReminders={handleUpdateReminders}/>
        },
        {
            key: 'attachments',
            label: 'Allegati',
            customComponent: <FilePicker handleUrlAttachment={handleUrlAttachment} handleDelete={handleDeleteAttachment} filesUrl={task.attachments}/>
        }
    ];

    return (
        <>
            <ModalForm
                open={open}
                type="fullScreen"
                animationType="slide"
                headerTitle={taskToEdit ? "Modifica la promemoria" : "Aggiungi promemoria"}
                footerMessage={"Ricordati di salvare il tuo promemoria"}
                saveButtonText={"Salva"}
                transparent={false}
                background={true}
                form={form}
                isLoading={isLoading}
                canDelete={taskToEdit}
                handleSave={handleSave}
                handleDelete={handleDelete}
                handleCancel={handleCancel}
            />
            {
                showErrorAlert && alertInfo && (
                <WebAlert
                    open={showErrorAlert}
                    setOpen={setShowErrorAlert}
                    title={alertInfo.title}
                    alertText={alertInfo.alertText}
                    handleConfirm={alertInfo.handleConfirm}
                    error={alertInfo.error}
                />
            )}
        </>
    )

};

export default TaskModal;
