import {Patient, Range, Reminder} from "../interfaces";

export const getDayOfWeek = (date) => {
    const daysOfWeek = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
    const dayNumber = new Date(date).getDay();
    return daysOfWeek[dayNumber];
};
export const validRange = ({startDate, endDate}) => {
    return startDate !== null || endDate !== null;
}

export const setDates = (reminders:Reminder[], range:Range) => {
    const { startDate, endDate } = range;
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const differenceInMilliseconds = end - start;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    reminders.forEach(reminder => {
        reminder.days.forEach(day => {
            const dates = [];
            for (let i = 0; i <= differenceInDays; i++) {
                const date = new Date(startDate);
                date.setDate(date.getDate() + i);
                if (day.id === date.getDay()) {
                    dates.push({
                        date: date.toISOString(),
                        status: day.status
                    });
                }
            }

            day.dates = dates;
        });

    });

    return reminders;
};

export const compareDaysMultipleReminders = (reminderA:Reminder, reminderB:Reminder) => {
    const currentDay = new Date().getDay();
    const daysA = reminderA.days.map(day => day.id);
    const daysB = reminderB.days.map(day => day.id);
    const closestDayA = Math.min(...daysA.map(day => Math.abs(day - currentDay)));
    const closestDayB = Math.min(...daysB.map(day => Math.abs(day - currentDay)));

    return closestDayA - closestDayB;
};

const circularDayDifference = (a, b) => {
    const daysInWeek = 7;
    return (b - a + daysInWeek) % daysInWeek;
}

export const compareDaysOneReminder = (dayA, dayB) => {
    const currentDay = new Date().getDay();

    const closestDayA = circularDayDifference(currentDay, dayA.id);
    const closestDayB = circularDayDifference(currentDay, dayB.id);

    return closestDayA - closestDayB;
};

export const getRemindersSorted = (reminders: Reminder[], range: Range) => {
    if (!reminders.length) {
        return [];
    }

    const now = new Date();

    const remindersSorted = reminders.map(reminder => {
        return {...reminder, days: reminder.days.sort(compareDaysOneReminder)};
    })

    if (validRange(range)) {
        remindersSorted.forEach(reminder => {
            reminder.days.forEach(day => {
                day.dates?.sort((a, b) => {
                    const diffA = Math.abs(now.getTime() - new Date(a.date).getTime());
                    const diffB = Math.abs(now.getTime() - new Date(b.date).getTime());

                    return diffA - diffB;
                });
            });
        });
    }

    return remindersSorted;
};

export const isNotificationSupported = () => {
    return 'Notification' in window &&
        'serviceWorker' in navigator &&
        'PushManager' in window
}

export const validPatients = (patients: any[]) => {
    return patients.filter(patient => patient.chatId !== null && patient.chatId !== undefined);
}

export const mapPatientToOption = (patients: any[]) => {
    return patients.map((patient: Patient) => {
        return {
            label: `${patient.firstname} ${patient.lastname}`,
            value: patient.uid,
        };
    });
};
