import { StyleSheet } from 'react-native'
import { colors, fonts } from '../../theme'

export const styles = StyleSheet.create({
    card: {
        backgroundColor: colors.cardBg,
        borderColor: colors.cardBorder,
        boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '10px',
        paddingVertical: '20px',
        paddingHorizontal: '20px',
        marginBottom: '10px',
    },
    cardHeader: {
        marginBottom: 5,
    },
    cardTitle: {
        color: colors.text,
        fontFamily: fonts.semiBold,
        fontSize: fonts.md,
        width:'80%'
    }
})
