import { StyleSheet } from "react-native";
import {colors, fonts} from "../../theme";


export const styles= StyleSheet.create({
    title: {
        fontFamily: fonts.firaSans,
        fontSize: fonts.sm,
        textAlign: 'center',
        marginBottom: 12
    },
    subtitle: {
        fontFamily: fonts.regular,
        fontSize: fonts.xs,
        textAlign: 'center',
        marginBottom: 12
    },
    formLabel: {
        color: colors.primary,
        fontFamily: fonts.semiBold,
        fontSize: fonts.xs,
        marginBottom: 8,
    },
    formInput: {
        backgroundColor: colors.backgroundLight,
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm,
        padding: 12,
        borderRadius: 12,
        borderWidth: 1,
        borderColor: colors.primary
    },
    button: {
        width: '100%',
        borderRadius: 12,
        padding: 12,
        marginTop: '40px',
        backgroundColor: colors.primary
    },
    buttonTitle: {
        fontFamily: fonts.firaSans,
        fontSize: fonts.xs,
        textAlign: 'center',
        color: colors.white
    },
    modal: {
        width: '330px',
        height: 'auto',
        borderRadius: '10px',
        backgroundColor: colors.backgroundLight,
        boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, 0.10);',
        padding: '20px'
    }
});

