import {Switch, Text, TouchableOpacity, View} from "react-native";
import {styles} from "../ReminderManager/styles";
import {AntDesign, MaterialCommunityIcons} from "@expo/vector-icons";
import {colors} from "../../../../theme";
import Card from "../../../../components/Card/Card";
import React from "react";
import {initialLetterOfDaysOfWeek} from "../../../../constants";


const ReminderList = ({ reminders, setReminders, setShowEditModal, setItemToEdit }) => {

    const toggleSwitch = (id) => {
        reminders.find((item) => item.id === id).active = !reminders.find((item) => item.id === id).active;
        setReminders([...reminders]);
    };

    return (
        <>
            {reminders.length === 0 ? (
                <View style={styles.noContentContainer}>
                    <Text style={styles.label}>Nessuna ricorrenza</Text>
                </View>
            ) : (
                reminders.map((item) => (
                    <TouchableOpacity onPress={() => {
                        setShowEditModal(true)
                        setItemToEdit({...item})
                    }} key={item.id}>
                        <Card key={item.id}>
                            <View section="header">
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Text style={styles.titleCard}>{item.displayTime}</Text>
                                    <TouchableOpacity>
                                        <Switch
                                            trackColor={{false: colors.backgroundGray, true: colors.primaryLight}}
                                            thumbColor={!item.active ? colors.backgroundDark : undefined}
                                            activeThumbColor={colors.primary}
                                            onValueChange={() => toggleSwitch(item.id)}
                                            value={item.active}
                                        />
                                    </TouchableOpacity>
                                </View>
                            </View>

                            <View section="body" style={{ flexDirection: 'row', width: '100%', overflow: 'hidden', marginTop: '15px'}}>
                                <View style={{ flexDirection: 'row', width: '100%' , overflow: 'hidden', justifyContent: 'space-between' }}>
                                    {
                                        initialLetterOfDaysOfWeek.map((day, index) => {
                                            const isDayInReminder = item.days.find((element) => element.id === day.id);
                                            return (
                                                <View style={{...styles.roundedBox, backgroundColor: isDayInReminder ? colors.primary : colors.backgroundGray }} key={index}>
                                                    <Text key={index} style={{ color: '#FFF'}}>{day.initial}</Text>
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                            </View>
                        </Card>
                    </TouchableOpacity>
                ))
            )}
            <TouchableOpacity onPress={() => setShowEditModal(true)} style={styles.addBtn}>
                <Text style={styles.btnText}>Aggiungi una ricorrenza</Text>
            </TouchableOpacity>
        </>
    );
};

export default ReminderList;
