import { StyleSheet } from 'react-native'
import {colors, fonts} from '../../theme'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
    paddingTop: 20,
    paddingHorizontal: '15px',
    width: '100%',
  },

  // List styles
  listContainer: {
    flex: 1
  },
  noContentContainer: {
    flex: 0.8,
    alignItems: 'center',
    justifyContent: 'center'
  },
  noContentImg: {
    tintColor: colors.yellow,
    width: 36,
    height: 36,
    marginBottom: 12
  },
  noContentText: {
    color: colors.text,
    fontFamily: fonts.medium,
    fontSize: fonts.sm
  },
  buttonPlus: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    width: '50px',
    height: '50px',
    borderRadius: 30,
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.shadow,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowRadius: 10,
    elevation: 5
  }
})
