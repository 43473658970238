import { StyleSheet } from 'react-native'
import { colors, fonts } from '../../../theme'

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.white,
        paddingTop: 30,
        paddingHorizontal: 24
    },
    screenTitle:{
      fontSize: fonts.md,
      fontWeight: fonts.bold,
      marginBottom: 20,
    },
    item: {
        /*backgroundColor: colors.cardBg,
        borderRadius: 10,
        padding: 15,
        marginVertical: 8,*/
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderColor: colors.textLight,
        borderRadius: 10,
        paddingVertical: 15,
        marginVertical: 8,
    },
    option:{
        flex: 1,
        flexDirection:'row',
        alignItems:'center',
    },
    optionTitle: {
        fontSize: fonts.sm,
        fontWeight: fonts.bold,
        color: colors.text,
        marginHorizontal: 10,
    },
    optionSubtitle: {
        fontSize: fonts.xxs,
        fontWeight: fonts.bold,
        color: colors.text,
        marginHorizontal: 10,
    }
})