// React Modules
import React, {useEffect, useState} from "react";
import {Text, View, TouchableOpacity, TextInput} from "react-native";

// Expo Modules
import * as DocumentPicker from "expo-document-picker";
import { Feather } from '@expo/vector-icons';

// Firebase
import Firebase from "../../service/Firebase";

// Components
import ProgressBar from "@ramonak/react-progress-bar";
import Card from "../Card/Card";
import CustomModal from "../customModal";



// Styles
import { styles } from "./styles";
import {colors} from "../../theme";

const FilePicker = ({ handleUrlAttachment, handleDelete, filesUrl }) => {
    const storage = Firebase.storage;

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [fileUrl, setFileUrl] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [errorLink, setErrorLink] = useState(false);

    useEffect(() => {
        if (filesUrl) {
            const filesStored = filesUrl.map((fileUrl) => {
                if (isUrlFirebaseStorage(fileUrl)) {
                    return storage.refFromURL(fileUrl);
                }
                return fileUrl;
            });

            setFiles(filesStored);
        }
    }, [filesUrl]);

    const pickDocument = async () => {
        let result = await DocumentPicker.getDocumentAsync({});
        const ref = storage.ref(`tasksAttachments/${result.name}`);

        setModalVisible(false);
        setLoading(true);
        const task = ref.put(result.file);
        task.on('state_changed', (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(progress);
        })
        await task;
        const url = await ref.getDownloadURL();

        handleUrlAttachment(url);

        setFiles([...files, ref]);
        setLoading(false);
    };

    const addAttachmentLink = () => {
        if (isValidLink(fileUrl)) {
            handleUrlAttachment(fileUrl);
            setFileUrl("");
            setModalVisible(false);
            setFiles([...files, fileUrl]);
        } else {
            setErrorLink(true);
        }
    }

    const isUrlFirebaseStorage = (url) => {
        return url.includes("firebasestorage.googleapis.com");
    }

    const isValidLink = (url) => {
        const fileLinkRegex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
        return fileLinkRegex.test(url);
    }

    return (
        <View style={styles.container}>
            {loading && (
                <View style={{ marginTop: 10, width: '100%' }}>
                    <ProgressBar completed={progress} maxCompleted={100} isLabelVisible={true} bgColor={colors.primary}/>
                </View>
            )}

            {files.length > 0 &&
                (files.map((file, index) => {
                        return (
                            <View key={index} style={{ marginTop: 8, marginBottom: '12px', width: '100%' }}>
                                <Card>
                                    <View section="header" style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <Feather name="file" size={24} color="black" style={{ marginRight: 10 }}/>
                                            <Text style={{ color: 'black' }}>{typeof file !== 'string' ? file.name : 'Link'}</Text>
                                            <Text style={{ color: 'black' }}>{}</Text>
                                        </View>
                                        <TouchableOpacity onPress={() => handleDelete(file, files, setFiles, index, typeof file !== 'string')}>
                                            <Feather name="x" size={24} color="black" />
                                        </TouchableOpacity>
                                    </View>
                                </Card>
                            </View>
                        )
                    })
                )
            }

            { modalVisible && (
                <CustomModal open={modalVisible} transparent={true} animationType="slide">
                    <View style={styles.modalHeader}>
                        <TouchableOpacity onPress={() => setModalVisible(false)}>
                            <Text style={styles.modalCancelBtnText}> Annulla </Text>
                        </TouchableOpacity>

                        <Text style={styles.modalTitle}> Anteprima allegato </Text>

                        <TouchableOpacity onPress={() => addAttachmentLink()}>
                            <Text style={styles.modalSaveBtnText}> Aggiungi </Text>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.modalBody}>
                        <TouchableOpacity onPress={pickDocument} disabled={loading} style={styles.buttonAttachment}>
                            <Text style={styles.addBtnText}>Aggiungi allegato</Text>
                        </TouchableOpacity>

                        <Text style={{ color: colors.placeholderTextColor, marginTop: 20, marginBottom: 20 }}> o </Text>

                        <View style={styles.modalBody}>
                            <Text style={{ color: colors.placeholderTextColor, marginBottom: 10 }}> Inserisci un link </Text>
                            <TextInput
                                onChangeText={(value) => {
                                    setFileUrl(value);
                                    setErrorLink(false);
                                }}
                                placeholder={"https://www.esempio.com/file"}
                                value={fileUrl}
                                style={styles.input}
                                placeholderTextColor={colors.placeholderTextColor}
                            />
                            { errorLink &&
                                <Text style={{ color: colors.red, marginTop: 10 }}> Link non valido </Text>
                            }
                        </View>
                    </View>
                </CustomModal>
            )}

            <TouchableOpacity onPress={() => setModalVisible(true)} disabled={loading} style={styles.addBtn}>
                <Text style={styles.addBtnText}>aggiungi allegato o link</Text>
            </TouchableOpacity>

        </View>
    );
};

export default FilePicker;
