import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';
import { Survey, Task } from "../interfaces";

class Firebase {
    firebase = firebase;
    timestamp = firebase.firestore.Timestamp;

    app: firebase.app.App;
    auth: firebase.auth.Auth;
    firestore: firebase.firestore.Firestore;
    storage: firebase.storage.Storage;
    messaging: firebase.messaging.Messaging;

    constructor() {
        // Initialize Firebase
        const config = {
            apiKey: process.env.EXPO_PUBLIC_FIREBASE_API_KEY,
            authDomain: process.env.EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.EXPO_PUBLIC_FIREBASE_DATABASE_URL,
            projectId: process.env.EXPO_PUBLIC_FIREBASE_PROJECT_ID,
            storageBucket: process.env.EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.EXPO_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.EXPO_PUBLIC_FIREBASE_APP_ID,
        }

        this.firebase.initializeApp(config);
        this.app = firebase.app();
        this.auth = firebase.auth();
        this.firestore = firebase.firestore();
        this.storage = firebase.storage();
        this.messaging = firebase.messaging();
    };

    async requestPushNotificationPermission() {
        try {
            const permission = await Notification.requestPermission()
            if (permission === 'granted') {
                return await this.messaging.getToken({
                    vapidKey: process.env.EXPO_PUBLIC_FIREBASE_VAPID_KEY,
                });
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async login(email:string, password: string) {
        try {
            const credentials =  await this.auth.signInWithEmailAndPassword(email, password);
            return credentials.user.uid;
        } catch (error) {
            throw error;
        }
    }

    async logout() {
        try {
            await this.auth.signOut();
        } catch (error) {
            console.log(error);
        }
    }

    async userData(uid: string) {
        try {
            const usersRef = firebase.firestore().collection('users');
            return await usersRef.doc(uid).get();
        } catch (error) {
            return null;
        }
    }

    async setNotificationPushToken(uid: string, token: string) {
        try {
            await firebase.firestore()
                .collection('users')
                .doc(uid)
                .update({
                    notificationPushToken: token,
                });
        } catch (error) {
            console.log(error);
        }
    }

    async completeUserSurvey(uid: string, surveyData: Survey) {
        try {
            await firebase.firestore()
                .collection('surveys')
                .add({
                    ...surveyData,
                    userID: uid,
                });
        } catch (error) {
            console.log(error);
        }
    }

    async updateLastLogin(uid: string) {
        try {
            await firebase.firestore()
                .collection('users')
                .doc(uid)
                .update({
                    lastLogin: new Date().toISOString(),
                });
        } catch (error) {
            console.log(error);
        }
    }

    async fetchPatients(userId: string) {
        try {
            const patientsRef = await firebase.firestore().
            collection('patients')
                .where('caregiverID', '==', userId)
                .where('deletedAt', '==', null).get();

            return patientsRef.docs.map(doc => ({...doc.data(), uid: doc.id}));
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async createTask(userId: string, task: any) {
        try {
            return await firebase.firestore()
                .collection('tasks')
                .add({
                    ...task,
                    caregiverID: userId,
                    createdAt: this.timestamp.now().toDate().toISOString(),
                    updatedAt: null,
                    deletedAt: null,
                });
        } catch (error) {
            console.log(error);
        }
    }

    async updateTask(task: Task) {
        try {
            const { updatedAt, ...taskData } = task;

            return await firebase.firestore()
                .collection('tasks')
                .doc(task.uid)
                .update({
                    ...taskData,
                    updatedAt: this.timestamp.now().toDate().toISOString()
                });
        } catch (error) {
            console.log(error);
        }
    }

    async deleteTask(uid: string) {
        try {
            return await firebase.firestore()
                .collection('tasks')
                .doc(uid)
                .update({
                    deletedAt: this.timestamp.now().toDate().toISOString()
                });
        } catch (error) {
            console.log(error);
        }
    }

    async createPatient(userId: string, patient: any) {
        try {
            return await firebase.firestore()
                .collection('patients')
                .add({
                    ...patient,
                    caregiverID: userId,
                    createdAt: this.timestamp.now().toDate().toISOString(),
                    updatedAt: null,
                    deletedAt: null,
                });
        } catch (error) {
            console.log(error);
        }
    }

    async updatePatient(patient: any, oldInfo: any) {
        try {
            const { updatedAt, ...patientData } = patient;

            // If the mobile number has changed, the chatId is reset
            if (patient.mobile !== oldInfo.mobile) {
                patientData.chatId = null;
            }

            return await firebase.firestore()
                .collection('patients')
                .doc(patient.uid)
                .update({
                    ...patientData,
                    updatedAt: this.timestamp.now().toDate().toISOString()
                });
        } catch (error) {
            console.log(error);
        }
    }

    async deletePatient(uid: string) {
        try {
            return await firebase.firestore()
                .collection('patients')
                .doc(uid)
                .update({
                    deletedAt: this.timestamp.now().toDate().toISOString()
                });
        } catch (error) {
            console.log(error);
        }
    }

    async checkPatientExists(phone:string, patientID: string) {
        try {
            const refDoc = await firebase.firestore()
                .collection('patients')
                .where('mobile', '==', phone)
                .where('deletedAt', '==', null)
                .get();

            if (patientID) {
                if (!refDoc.docs.length) {
                    return false;
                }

                const patient = {...refDoc.docs[0].data(), id: refDoc.docs[0].id};
                return patient.id !== patientID;
            }

            return refDoc.docs.length > 0;
        } catch (error) {
            console.log(error.message);
        }
    }

    async checkSurveyExists(userId: string) {
        try {
            const refDoc = await firebase.firestore()
                .collection('surveys')
                .where('userID', '==', userId)
                .get();

            return refDoc.docs.length > 0;
        } catch (error) {
            console.log(error.message);
        }
    }

    async deletePatientTasks(patientID: string) {
        try {
            const tasksRef = await firebase.firestore()
                .collection('tasks')
                .where('patientID', '==', patientID)
                .get();

            for (const doc of tasksRef.docs) {
                await doc.ref.update({
                    deletedAt: this.timestamp.now().toDate().toISOString()
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export default Object.freeze(new Firebase());
