import { View, Text, TouchableOpacity } from "react-native";

import CustomModal from "../customModal";
import Button from "../Button";

import {colors, fonts} from "../../theme";
const webAlert = ({ open, setOpen, title, alertText, handleCancel, handleConfirm, confirmText, loading = false, error }) => {
    return (
        <CustomModal
            open={open}
            transparent={true}
        >
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <View>
                    <Text style={{ fontSize: fonts.sm , fontWeight: fonts.semiBold, marginBottom: 15 }}>{title}</Text>
                </View>
                <>
                    <Text>{ alertText }</Text>
                    { handleConfirm ? (
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%', marginTop: 20 }}>
                            <TouchableOpacity style={{ justifyContent: 'center' }} onPress={() => handleCancel ? handleCancel() : setOpen(false)}>
                                <Text style={{ color: colors.blue }}>Annulla</Text>
                            </TouchableOpacity>

                            <Button
                                title={confirmText ? confirmText : 'Elimina'}
                                handlePress={handleConfirm}
                                withoutBg={true}
                                loading={loading}
                                loaderColor={colors.primary}
                                textColor={colors.red}
                            />
                        </View>
                    ) : (
                        <View style={{ marginTop: 15, backgroundColor: error ? colors.red : colors.green, padding: 12, borderRadius: 12}}>
                            <TouchableOpacity onPress={() => setOpen(false)}>
                                <Text style={{ color: colors.white }}>Ok</Text>
                            </TouchableOpacity>
                        </View>
                    )
                    }
                </>
            </View>
        </CustomModal>
    );
};

export default webAlert;
