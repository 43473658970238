import Svg, { Circle, Rect, Path, G } from "react-native-svg";
import {colors} from "../../theme";


const TaskIcon = ({ focused }) => {
    return (
        <>
            <Svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"
                 style={{position: 'relative'}}>
                <Circle cx="19" cy="19" r="19" fill={focused ? colors.primary : colors.primaryLight}
                        stroke={focused ? colors.primary : ""}/>
            </Svg>

            <Svg x="0px" y="0px" viewBox="0 0 24 27" style={{position: 'absolute'}}>
                <G>
                    <Path  fill={colors.white} d="M11,6h10c0.6,0,1-0.4,1-1s-0.4-1-1-1H11c-0.6,0-1,0.4-1,1S10.4,6,11,6z M21.5,5L21.5,5L21.5,5L21.5,5z"/>
                    <Path  fill={colors.white}
                        d="M6,16H4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-2C8,16.9,7.1,16,6,16z M4,18h2v2H4c0,0,0,0,0,0V18z"/>
                    <Path fill={colors.white} d="M6,9H4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-2C8,9.9,7.1,9,6,9z M4,11h2v2l-2,0l0,0V11z"/>
                    <Path fill={colors.white} d="M21,11H11c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S21.6,11,21,11z"/>
                    <Path fill={colors.white} d="M6,2H4C2.9,2,2,2.9,2,4v2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2V4C8,2.9,7.1,2,6,2z M4,4h2v2L4,6l0,0V4z"/>
                    <Path fill={colors.white} d="M21,18H11c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S21.6,18,21,18z"/>
                </G>
            </Svg>
        </>
    );
};

export default TaskIcon;
