import React from "react";
import {View, Text, TouchableWithoutFeedback} from "react-native";
import { styles } from "./styles";

const Card = (props) => {

    const getSectionContent = (section) => {
        const sectionContent = React.Children.toArray(props.children).filter(
            (child) => child.props && child.props.section === section
        );
        return sectionContent.length > 0 ? sectionContent : null;
    };

    return (
        <View style={styles.card}>

            {
                getSectionContent('header') && (
                    <View style={styles.cardHeader}>
                        {getSectionContent('header')}
                    </View>
                )
            }

            {
                getSectionContent('body') && (
                    <View>
                        {getSectionContent('body')}
                    </View>
                )
            }

            {
                getSectionContent('footer') && (
                    <View>
                        {getSectionContent('footer')}
                    </View>
                )
            }

        </View>
    )
}


export default Card;