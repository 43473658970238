// React modules
import React, {useEffect, useState} from "react";

//store
import useStore from "../../../../store";
import useAuthStore from "../../../../store/auth-store";

//components
import ModalForm from "../../../../components/ModalFrom";
import WebAlert from "../../../../components/WebAlert";
import {useDropdown} from "../../../../hooks";

// others
import {ages, genres, persona} from "../../../../constants";
import { initialStatePatient } from "../../../../constants";

const PatientModal = ({ open, setOpen, patients, flatListRef, edit, patientToEdit = null, resetPatientToEdit }) => {
    const user = useAuthStore(state => state.user);

    const [patient, setPatient] = useState(initialStatePatient(user));
    const [countryCode, setCountryCode] = useState(undefined);
    const [phone, setPhone] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [alertInfo, setAlertInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const genreDropdown = useDropdown(genres);
    const ageDropdown = useDropdown(ages);
    const connectionDropdown = useDropdown(persona);

    const checkPatientExists = useStore(state => state.checkPatientExists);
    const createPatient = useStore(state => state.createPatient);
    const updatePatient = useStore(state => state.updatePatient);
    const deletePatient = useStore(state => state.deletePatient);
    const deletePatientTasks = useStore(state => state.deletePatientTasks);

    useEffect(() => {
        if (edit) {
            genreDropdown.setDropdownValue(patientToEdit.genre);
            ageDropdown.setDropdownValue(patientToEdit.age);
            connectionDropdown.setDropdownValue(patientToEdit.connection);
            setPatient(patientToEdit)
        }
    },[open, edit]);

    const formattedPhoneNumber = () => {
        if (!countryCode) return patient.mobile;

        return `${countryCode.callingCode}${phone.replace(/ /g, '')}`;
    };

    const handleCancel = () => {
        setPatient(initialStatePatient(user));
        resetPatientToEdit();
        setOpen(false);

    };

    const handleErrors = async () => {
        const errors = {
            Nome: patient.firstname === "",
            Cognome: patient.lastname === "",
            Cellulare: phone === "",
            Eta: ageDropdown.dropdownValue === "",
            Genere: genreDropdown.dropdownValue === "",
            Connessione: connectionDropdown.dropdownValue === "",
            AltroCollegamento: connectionDropdown.dropdownValue === "altro" && patient.otherConnection === "",
        };

        const missingFields = Object.entries(errors)
            .filter(([field, hasError]) => hasError)
            .map(([field]) => field);

        setAlertInfo({
            title: "Completare tutti i campi",
            alertText: `Devi completare i seguenti campi: \n ${missingFields.join(", ")}`,
            error: true
        });

        if (missingFields.length > 0) {
            setShowAlert(true);
            setIsLoading(false)
            return false;
        }

        if (await checkPatientExists(formattedPhoneNumber(), patientToEdit !== null ? patientToEdit.uid : null)) {
            setAlertInfo({
                title: "Il paziente esiste già",
                alertText: "Esiste già un paziente con questo numero di telefono",
                error: true
            });
            setShowAlert(true);
            setIsLoading(false);
            return false;
        }

        return true;
    };

    const handleSave = async () => {
        setIsLoading(true);
        if (!await handleErrors()) return;

        const data = {
            ...patient,
            caregiver: user.displayName,
            fullname: `${patient.firstname} ${patient.lastname}`,
            mobile: formattedPhoneNumber(),
            age: String(ageDropdown.dropdownValue) ?? "",
            genre: String(genreDropdown.dropdownValue) ?? "",
            connection: String(connectionDropdown.dropdownValue) ?? "",
            otherConnection: connectionDropdown.dropdownValue === "altro" ? patient.otherConnection : "",
        }

        if (edit) {
            updatePatient(data);
            setIsLoading(false);
            setPatient(initialStatePatient(user));
            resetPatientToEdit();
            setOpen(false);
            return;
        }

        createPatient(user.uid, data);
        setIsLoading(false);
        setPatient(initialStatePatient(user));
        setOpen(false);
        if (patients.length > 1) flatListRef.current.scrollToEnd();
    };

    const handleDelete = () => {
        setAlertInfo({
            title: "Elimina patient",
            alertText: "Conferma l’eliminazione",
            handleConfirm: async () => {
                deletePatient(patientToEdit.uid);
                deletePatientTasks(patientToEdit.uid);
                setPatient(initialStatePatient(user));
                resetPatientToEdit();
                setOpen(false);
                setShowAlert(false)
            },
            error: false
        });
        setShowAlert(true);
    }

    const form = [
        {
            key: 'firstname',
            label: 'Nome',
            type: 'textInput',
            handleChange: (value) => setPatient({...patient, firstname: value}),
            placeholder: 'Aggiungere il nome del paziente',
            value: patient.firstname
        },
        {
            key: 'lastname',
            label: 'Cognome',
            type: 'textInput',
            handleChange: (value) => setPatient({...patient, lastname: value}),
            placeholder: 'Aggiungere il cognome del paziente',
            value: patient.lastname
        },
        {
            key: 'mobile',
            label: 'Cellulare',
            helperText: 'Il numero inserito deve essere collegato ad un account Telegram per ricevere i promemoria.',
            type: 'phone',
            defaultCode: "IT",
            defaultValue: patient.mobile,
            selectedCountry: countryCode,
            handleChange: (value) => (setPhone(value)),
            handleChangeCountry: (value) => (setCountryCode(value)),
            value: phone
        },
        {
            key: "genere",
            label: "Genere",
            type: "dropdown",
            placeholder: "Genere",
            dropdownOpen: genreDropdown.dropdownOpen,
            setIsDropdownOpen: genreDropdown.setIsDropdownOpen,
            dropdownValue: genreDropdown.dropdownValue,
            setDropdownValue: genreDropdown.setDropdownValue,
            items: genreDropdown.items,
            setItems: genreDropdown.setItems,
        },
        {
            key: "age",
            label: "Età",
            type: "dropdown",
            placeholder: "Etá",
            dropdownOpen: ageDropdown.dropdownOpen,
            setIsDropdownOpen: ageDropdown.setIsDropdownOpen,
            dropdownValue: ageDropdown.dropdownValue,
            setDropdownValue: ageDropdown.setDropdownValue,
            items: ageDropdown.items,
            setItems: ageDropdown.setItems,
        },
        {
            key: "connection",
            label: "Quale legame hai con la persona?",
            type: "dropdown",
            placeholder: "Select",
            dropdownOpen: connectionDropdown.dropdownOpen,
            setIsDropdownOpen: connectionDropdown.setIsDropdownOpen,
            dropdownValue: connectionDropdown.dropdownValue,
            setDropdownValue: connectionDropdown.setDropdownValue,
            items: connectionDropdown.items,
            setItems: connectionDropdown.setItems,
        },
        {
            key: 'customConnection',
            label: 'Altro',
            type: 'textInput',
            isVisible: connectionDropdown.dropdownValue === 'altro',
            handleChange: (value) => setPatient({...patient, otherConnection: value}),
            placeholder: 'Altro',
            value: patient.otherConnection
        },
    ];

    return (
        <>
            <ModalForm
                open={open}
                headerTitle={edit ? "Modifica assistito" : "Aggiungi assistito"}
                form={form}
                transparent={true}
                handleCancel={handleCancel}
                handleSave={handleSave}
                handleDelete={handleDelete}
                isLoading={isLoading}
                saveButtonText="SALVA"
                deleteButtonText="Elimina assistito"
                canDelete={edit}
            />
            <WebAlert
                open={showAlert}
                setOpen={setShowAlert}
                title={alertInfo.title}
                alertText={alertInfo.alertText}
                handleConfirm={alertInfo.handleConfirm}
                error={alertInfo.error}
            />
        </>
    );
};

export default PatientModal;
