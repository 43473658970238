import React, { useState } from "react";
import { ImageBackground, Linking, Text, TouchableOpacity, View } from "react-native";

import { Header } from "../../components";
import ReAuthModal from "../../components/ReAuthModal";
import WebAlert from "../../components/WebAlert";
import ConfigurationList from "./components/ConfigurationList";
import ChangePasswordModal from "./components/ChangePasswordModal";

//Store
import useStore from "../../store";
import useAuthStore from "../../store/auth-store";

//Firebase
import Firebase from "../../service/Firebase";

//Styles
import { Feather } from "@expo/vector-icons";
import { MaterialIcons } from '@expo/vector-icons';
import { colors, globalStyles } from "../../theme";
import { styles } from "./styles";

//Others
import { isNotificationSupported } from '../../utils'

const EMAIL_SUPPORT = process.env.EXPO_PUBLIC_EMAIL_SUPPORT;
const SettingsScreen = () => {
    const user = useAuthStore(state => state.user);
    const logout = useAuthStore(state => state.logout);

    const [reAuthModal, setReAuthModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [notificationPermissionModal, setNotificationPermissionModal] = useState(false);
    const [alert, setAlert] = useState({});
    const [alertLoading, setAlertLoading] = useState(false);

    const handleReAuthenticate = () => {
      setReAuthModal(false);
      setChangePasswordModal(true);
    };

    const notificationStatus = () => {
        if ( isNotificationSupported() ) {
            const status = Notification.permission
            return {
                title: status === 'granted' ? 'Le notifiche sono abilitate' : 'Le notifiche sono disabilitate',
                customIcon: status === 'granted' ? <MaterialIcons name="notifications-active" size={24} color={colors.primary} style={{marginRight: 10}}/> : <MaterialIcons name="notifications-none" size={24} color={colors.primary} style={{marginRight: 10}}/>,
            }
        }

        return {
            title: 'Notifiche non supportate',
            customIcon: <MaterialIcons name="notifications-off" size={24} color={colors.primary} style={{marginRight: 10}}/>
        }
    }

    const requestNotificationPermission = async () => {
        if (!isNotificationSupported()) {
            setAlert({
                title: "Notifiche",
                alertText: "Le notifiche non sono supportate su questo browser.",
                handleConfirm: false,
                confirmText: "",
                error: true
            });
            setNotificationPermissionModal(true);
            return;
        }


        if (Notification.permission === 'granted') {
            setAlert({
                title: "Notifiche",
                alertText: "Le notifiche sono abilitate, se vuoi disabilitarle apri le preferenze del browser o fai clic sul lucchetto 🔒 accanto alla barra degli indirizzi per modificare le preferenze di notifica.",
                handleConfirm: false,
                confirmText: "",
                error: false
            });
            setNotificationPermissionModal(true);
        }

        if (Notification.permission === 'denied') {
            setAlert({
                title: "Notifiche",
                alertText: "Le notifiche sono bloccate, abilitatele a ricevere le notifiche push. Apri le preferenze del browser o fai clic sul lucchetto 🔒 accanto alla barra degli indirizzi per modificare le preferenze di notifica.",
                handleConfirm: false,
                confirmText: "",
                error: true
            });
            setNotificationPermissionModal(true);
        }

        if (Notification.permission === 'default') {
            setAlert({
                title: "Notifiche",
                alertText: "Vuoi attivare le notifiche push?",
                handleConfirm: async () => {
                    setAlertLoading(true);
                    const tokenMessaging = await Firebase.requestPushNotificationPermission()
                    if (user?.uid && tokenMessaging) {
                        await Firebase.setNotificationPushToken(user.uid, tokenMessaging);
                        setNotificationPermissionModal(false);
                        setAlertLoading(false);
                    }
                    setNotificationPermissionModal(false);
                    setAlertLoading(false);
                },
                confirmText: "Attiva",
                error: false
            })
            setNotificationPermissionModal(true);
        }
    }

    const configurations = [
        {
            data: [
                {title: 'Cambia la password', subtitle: '', icon: 'lock', custom: () => setReAuthModal(true) },
                {title: 'Supporto tecnico', subtitle: '', icon: 'envelope-open-text', custom: () => Linking.openURL(`mailto:${EMAIL_SUPPORT}?subject=Supporto%20app%20Caregiver`)},
                { title: notificationStatus().title, subtitle: '', customIcon: notificationStatus().customIcon, custom: () => requestNotificationPermission() },
            ]
        }
    ]

    return (
        <ImageBackground source={require('../../assets/bg-primary.png')} style={globalStyles.backgroundImage}>
            <View style={styles.container}>
                <Header
                    title="Preferenze"
                    subtitle="Il tuo account"
                />
                <ConfigurationList configurations={configurations}/>
                <ReAuthModal
                    open={reAuthModal}
                    setOpen={setReAuthModal}
                    handleReAuthenticate={handleReAuthenticate}
                />
                <ChangePasswordModal
                    open={changePasswordModal}
                    setOpen={setChangePasswordModal}
                />
                { notificationPermissionModal &&
                    (
                        <WebAlert
                            open={notificationPermissionModal}
                            setOpen={setNotificationPermissionModal}
                            title={alert.title}
                            alertText={alert.alertText}
                            handleConfirm={alert.handleConfirm}
                            confirmText={alert.confirmText}
                            error={alert.error}
                            loading={alertLoading}
                        />
                    )
                }
            </View>
            <TouchableOpacity
                onPress={() => logout()}
                style={styles.logoutBtn}
            >
                <View style={styles.option}>
                    <View>
                        <Text style={styles.logoutText}> Log out </Text>
                    </View>
                </View>
                <Feather name="arrow-right" size={24} color={colors.red} />
            </TouchableOpacity>
        </ImageBackground>
    );
};

export default SettingsScreen;
