import { create } from 'zustand';
import { useFonts } from "expo-font";
import { fonts } from "../constants";
import { GlobalState } from "../interfaces/store";

import Firebase from "../service/Firebase";

const useStore = create<GlobalState>((setState, getState) => ({
    loading: false,
    tasks: [],
    patients: [],
    setLoading: (newValue: boolean) => setState({ loading: newValue }),
    setFonts: () => {
        const [loaded] = useFonts(fonts);

        if (!loaded) {
            return null;
        }

        return loaded;
    },
    fetchPatients: async (userId: string) => {
        setState({ loading: true });
        const patients = await Firebase.fetchPatients(userId);
        setState({ patients });
        setState({ loading: false });
    },
    setTasks: (task: any) => setState({ tasks: task }),
    setPatients: async (patients: any) => setState({ patients }),
    createTask: async (userId, task) => {
        const newTask = await Firebase.createTask(userId, task);
        const { tasks } = getState();
        tasks.push({ ...task, uid: newTask.id });
        setState({ tasks });
    },
    updateTask: async (task) => {
        await Firebase.updateTask(task);
        const { tasks } = getState();
        const newTasks = tasks.map((t) => {
            if (t.uid === task.uid) {
                return task;
            }
            return t;
        });

        setState({ tasks: newTasks });
    },
    deleteTask: async (uid: string) => {
        await Firebase.deleteTask(uid);
        const { tasks } = getState();
        const newTasks = tasks.filter((t) => t.uid !== uid);
        setState({ tasks: newTasks });
    },
    createPatient: async (userId, patient) => {
        const newPatient = await Firebase.createPatient(userId, patient);
        const { patients } = getState();
        const newPatients = [...patients, { ...patient, uid: newPatient.id }];
        setState({ patients: newPatients });
    },
    updatePatient: async (patient: any) => {
        const { patients } = getState();
        const oldPatient = patients.find((p) => p.uid === patient.uid);

        await Firebase.updatePatient(patient, oldPatient);
        const newPatients = patients.map((p) => {
            if (p.uid === patient.uid) {
                return patient;
            }
            return p;
        });
        setState({ patients: newPatients });
    },
    deletePatient: async (uid: string) => {
        await Firebase.deletePatient(uid);
        const { patients } = getState();
        const newPatients = patients.filter((p) => p.uid !== uid);
        setState({ patients: newPatients });
    },
    deletePatientTasks: async (uid: string) => {
        const { tasks } = getState();
        const newTasks = tasks.filter((t) => t.patientID !== uid);
        setState({ tasks: newTasks });
        await Firebase.deletePatientTasks(uid);
    },

    checkPatientExists: async (phone, uid) => {
        return await Firebase.checkPatientExists(phone, uid);
    },
}));


export default useStore;


