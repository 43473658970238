// React modules
import React, {useEffect, useState} from "react";
import {View} from "react-native";

//Components
import DateTimePickerModal from "../DateTimePickerModal";
import WebAlert from "../../../../components/WebAlert";
import ReminderList from "../ReminderList";

//Styles
import { styles } from "./styles";


const ReminderManager = ({ taskReminders, updateTaskReminders }) => {
    const [ reminders, setReminders ] = useState(taskReminders);
    const [ itemToEdit, setItemToEdit ] = useState(null);
    const [showDateTimeModal, setShowDateTimeModal] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertInfo, setAlertInfo] = useState(null);

    useEffect(() => {
        setReminders(taskReminders)
    }, [taskReminders]);

    useEffect(() => {
        updateTaskReminders(reminders);
    }, [reminders]);

    const saveData = (reminder, setShow) => {
        const index = reminders.findIndex(element => element.id === reminder.id);
        if (index !== -1) {
            reminders[index] = reminder;
            setReminders([...reminders]);
        } else {
            setReminders([...reminders, reminder]);
        }
        setShowDateTimeModal(false);
    };

    const onDelete = async (id) => {
        const index = reminders.findIndex(element => element.id === itemToEdit.id);
        reminders.splice(index, 1);
        setReminders([...reminders]);
        setShowAlert(false);
        setShowDateTimeModal(false)
    };

    return (
        <View style={styles.container}>
            <ReminderList
                reminders={reminders}
                setReminders={setReminders}
                setShowEditModal={setShowDateTimeModal}
                setItemToEdit={setItemToEdit}
            />
            { showDateTimeModal && (
                <DateTimePickerModal
                    open={showDateTimeModal}
                    setOpen={setShowDateTimeModal}
                    saveData={saveData}
                    item={itemToEdit ?? null}
                    setItem={setItemToEdit}
                    onDelete={(id) => {
                        setAlertInfo({
                            title: "Elimina reminder",
                            message: "Conferma l’eliminazione",
                            onConfirm: () => onDelete(id)
                        })
                        setShowAlert(true)
                    }}
                />
            )}
            {showAlert && alertInfo && (
                <WebAlert
                    open={showAlert}
                    setOpen={setShowAlert}
                    title={alertInfo.title}
                    alertText={alertInfo.message}
                    handleConfirm={alertInfo.onConfirm}
                />
            )}
        </View>
    )
};

export default ReminderManager;
