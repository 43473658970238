import React from "react";
import {SectionList, Text, TouchableOpacity, View} from "react-native";

//Styles
import { Feather, FontAwesome5 } from "@expo/vector-icons";
import { styles} from "../../styles";
import { colors} from "../../../../theme";

const ConfigurationList = ({ configurations, navigation }) => {
    const renderItem = ({ item }) => (
        <View style={styles.item}>
            <TouchableOpacity
                onPress={() => item.custom() }
                style={styles.option}
            >
                <View style={styles.option}>
                    { item.icon && <FontAwesome5 name={item.icon} size={24} color={colors.primary} style={{marginRight: 10}}/> }
                    { item.customIcon && item.customIcon }
                    <View>
                        <Text style={styles.optionTitle}>{item.title}</Text>
                        {
                            item.subtitle && <Text style={styles.optionSubtitle}>{item.subtitle}</Text>
                        }
                    </View>
                </View>
                <Feather name="arrow-right" size={24} color={colors.primary} />
            </TouchableOpacity>
        </View>
    );

    return (
        <>
            <SectionList
                sections={configurations}
                renderItem={renderItem}
                renderSectionHeader={({section}) => (
                    <Text style={styles.sectionHeader}>{section.title}</Text>
                )}
            />
            <View
                style={{
                    borderBottomColor: '#D5D5D5',
                    borderBottomWidth: 1,
                }}
            />
        </>
    );
}

export default ConfigurationList;
