import React, {useState} from 'react';
import {TextInput, View, TouchableOpacity, Text} from "react-native";

//Store
import useAuthStore from "../../store/auth-store";
import useStore from "../../store";

//Services
import Firebase from "../../service/Firebase";
import {EmailAuthProvider, reauthenticateWithCredential} from "firebase/auth";

//Components
import CustomModal from "../customModal";
import WebAlert from "../WebAlert";
import CloseIcon from "../Svg/Close";
import Button from "../Button";

//Styles
import {styles} from './styles';

const ReAuthModal = ({ open, setOpen, handleReAuthenticate }) => {
    const user = useAuthStore(state => state.user);

    const [password, setPassword] = useState('');

    const [alertModal, setAlertModal] = useState(false);
    const [alerInfo, setAlerInfo] = useState(null);
    const [loading, setLoading] = useState(false);

    const onLoginPress = () => {
        setLoading(true);
        const credentials = EmailAuthProvider.credential(user.email, password);
        reauthenticateWithCredential(Firebase.auth.currentUser, credentials).then(() => {
            setLoading(false);
            handleReAuthenticate();
        }).catch((err) => {
            setAlerInfo({
                title: 'Error',
                text: err.message,
                error: true
            });
            setAlertModal(true);
        });
    }

    return (
        <>
            <CustomModal open={open} transparent={true} type="custom" animationType="slide">
                <View style={styles.modal}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.title}>Effettua il login</Text>
                        <TouchableOpacity onPress={() => setOpen(false)}>
                            <CloseIcon />
                        </TouchableOpacity>
                    </View>
                    <Text style={styles.formLabel}>Password</Text>
                    <TextInput
                        style={styles.formInput}
                        value={password}
                        placeholderTextColor="#aaa"
                        underlineColorAndroid="transparent"
                        placeholder="********"
                        autoCapitalize="none"
                        secureTextEntry
                        onChangeText={(text) => setPassword(text)}
                    />
                    <Button title="ACCEDI" loading={loading} handlePress={onLoginPress}/>
                </View>
            </CustomModal>
            {
                alertModal && alerInfo &&
                <WebAlert open={alertModal} setOpen={setAlertModal} title={alerInfo.title} alertText={alerInfo.text} error={alerInfo.error}/>
            }
        </>
    );
}

export default ReAuthModal;
