import React, { useState } from "react";
import { Text, TouchableOpacity, ActivityIndicator } from "react-native";
import styles from "./styles";

const Button = ({ title, handlePress, loading, color, textColor, customStyles, withoutBg, loaderColor }) => {

    return (
        <TouchableOpacity
            style={[
                styles.button,
                withoutBg && { backgroundColor: "transparent", border: 'none', width: 'auto', margin: 0 },
                color && { backgroundColor: color },
                customStyles && customStyles,
            ]}
            onPress={() => handlePress()}
            disabled={loading}
        >
            {loading ? (
                <ActivityIndicator size="small" color={ loaderColor ? loaderColor : styles.buttonTitle.color} />
            ) : (
                <Text style={[styles.buttonTitle, textColor && { color: textColor }]}>{title}</Text>
            )}
        </TouchableOpacity>
    );
};

export default Button;
