import { StyleSheet } from "react-native";
import { colors, fonts } from "../../theme";

export const styles = StyleSheet.create({
  header: {
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonStyle: {
    alignItems: "center",
  },
  logo: {
    display: 'block',
    height: 25,
    width: 130,
    alignSelf: "center",
  },
  headerHeading: {
    color: colors.black,
    fontFamily: 'Fira Sans',
    fontSize: fonts.md,
    fontWeight: '400',
    lineHeight: 'normal',
    fontStyle: 'normal',
  },
  headerSubheading: {
    color: colors.textOpacity,
    fontFamily: 'Fira Sans',
    fontSize: fonts.xs,
    fontWeight: '400',
    lineHeight: 'normal',
    fontStyle: 'normal',
  },
  buttonText: {
    color: colors.green,
    fontFamily: fonts.bold,
    fontSize: fonts.xs,
    marginTop:4
  },
  button: {
    width: 40,
    height: 40,
    borderRadius: 50,
    backgroundColor: colors.backgroundDark,
    justifyContent: "center",
    alignItems: "center",
  },

});
