import Svg, { Circle, G, Path, Mask } from "react-native-svg";
import {colors} from "../../theme";

const NutIcon = ({ focused }) => {
    return (
        <>
            <Svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"
                 style={{position: 'relative'}}>
                <Circle cx="19" cy="19" r="19" fill={focused ? colors.primary : colors.primaryLight}
                        stroke={focused ? colors.primary : ""}/>
            </Svg>

            <Svg x="0px" y="0px" viewBox="0 0 20 23"  style={{position: 'absolute'}}>
                <G>
                    <Path fill={colors.white} d="M19.2,8l-1.6-0.4c-0.1-0.5-0.3-0.9-0.6-1.4l0.8-1.4c0.2-0.4,0.2-0.9-0.1-1.2l-1.4-1.4c-0.3-0.3-0.8-0.4-1.2-0.2l-1.4,0.9   c-0.4-0.2-0.9-0.4-1.4-0.6L12,0.8C11.9,0.3,11.5,0,11,0H9C8.5,0,8.1,0.3,8,0.8L7.6,2.4C7.2,2.5,6.7,2.7,6.3,2.9L4.9,2.1   C4.5,1.8,4,1.9,3.6,2.2L2.2,3.6C1.9,4,1.8,4.5,2.1,4.9l0.8,1.4C2.7,6.7,2.5,7.2,2.4,7.6L0.8,8C0.3,8.1,0,8.5,0,9v2   c0,0.5,0.3,0.9,0.8,1l1.6,0.4c0.1,0.5,0.3,0.9,0.6,1.4l-0.8,1.4c-0.2,0.4-0.2,0.9,0.1,1.2l1.4,1.4c0.3,0.3,0.8,0.4,1.2,0.2l1.4-0.9   c0.4,0.2,0.9,0.4,1.4,0.6L8,19.2C8.1,19.7,8.5,20,9,20h2c0.5,0,0.9-0.3,1-0.8l0.4-1.6c0.5-0.1,0.9-0.3,1.4-0.6l1.4,0.9   c0.4,0.2,0.9,0.2,1.2-0.2l1.4-1.4c0.3-0.3,0.4-0.8,0.1-1.2l-0.8-1.4c0.2-0.4,0.4-0.9,0.6-1.4l1.6-0.4c0.4-0.1,0.8-0.5,0.8-1V9   C20,8.5,19.7,8.1,19.2,8z M18,10.2l-1.4,0.4c-0.4,0.1-0.6,0.4-0.7,0.7c-0.2,0.7-0.4,1.3-0.8,1.9c-0.2,0.3-0.2,0.7,0,1.1l0.8,1.3   l-0.3,0.3l-1.3-0.8c-0.3-0.2-0.7-0.2-1.1,0c-0.5,0.4-1.2,0.6-1.9,0.8c-0.4,0.1-0.7,0.4-0.7,0.7L10.2,18H9.8l-0.4-1.4   c-0.1-0.4-0.4-0.7-0.7-0.7c-0.7-0.2-1.3-0.4-1.9-0.8c-0.3-0.2-0.7-0.2-1.1,0l-1.3,0.8l-0.3-0.3l0.8-1.3c0.2-0.3,0.2-0.7,0-1.1   c-0.4-0.6-0.6-1.2-0.8-1.9c-0.1-0.4-0.4-0.7-0.7-0.7L2,10.2V9.8l1.4-0.4C3.8,9.3,4.1,9,4.1,8.7C4.3,8,4.6,7.4,4.9,6.8   c0.2-0.3,0.2-0.7,0-1.1L4.2,4.5l0.3-0.3l1.3,0.8c0.3,0.2,0.7,0.2,1.1,0C7.4,4.6,8,4.3,8.7,4.2C9,4.1,9.3,3.8,9.4,3.4L9.8,2h0.4   l0.4,1.4c0.1,0.4,0.4,0.7,0.8,0.7c0.7,0.1,1.3,0.4,1.9,0.8c0.3,0.2,0.7,0.2,1.1,0l1.3-0.8l0.3,0.3l-0.8,1.3c-0.2,0.3-0.2,0.7,0,1.1   c0.4,0.6,0.6,1.2,0.8,1.9c0.1,0.4,0.4,0.7,0.7,0.7L18,9.8V10.2z"/>
                    <Path fill={colors.white} d="M10,6c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4s4-1.8,4-4C14,7.8,12.2,6,10,6z M10,12c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2   C12,11.1,11.1,12,10,12z"/>
                </G>
            </Svg>
        </>
    );
};

export default NutIcon;
