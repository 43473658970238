import { StyleSheet } from "react-native";
import {colors, fonts} from "../../../../theme";

export const styles = StyleSheet.create({
    title: {
        fontFamily: fonts.firaSans,
        fontSize: fonts.sm,
        textAlign: 'center',
        marginBottom: 12
    },
    subtitle: {
        fontFamily: fonts.regular,
        fontSize: fonts.sm,
        textAlign: 'center',
        marginBottom: 12
    },
    label: {
        color: colors.primary,
        fontFamily: fonts.semiBold,
        fontSize: fonts.xs,
        marginBottom: 8,
        marginLeft: '10px',
    },
    form: {

    },
    formGroup: {
        flexDirection: 'column',
        marginBottom: 12
    },
    formInput: {
        backgroundColor: colors.backgroundLight,
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm,
        padding: 12,
        borderRadius: 12,
        borderWidth: 1,
        borderColor: colors.primary
    },

    button: {
        width: '100%',
        borderRadius: 12,
        padding: 12,
        marginTop: 12,
        backgroundColor: colors.primary
    },
    buttonTitle: {
        fontFamily: fonts.firaSans,
        fontSize: fonts.sm,
        textAlign: 'center',
        color: colors.white
    },
    cancelButton: {
        width: '100%',
        borderRadius: 12,
        padding: 12,
        marginTop: 12,
        backgroundColor: colors.red
    }
});
