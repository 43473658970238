// React styles
import { StyleSheet } from "react-native";
import { colors } from "../../theme";

export const styles = StyleSheet.create({
    roundBox: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 10,
        borderRadius: 50,
        borderStyle: 'solid',
        borderWidth: 1,
        height: 25,
        width: 25,
    },
})
