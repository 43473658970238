import {ImageBackground, Image, Text, ScrollView} from "react-native";
import CustomModal from "../customModal";
import Form from "../Form";
import { styles } from "./styles";
import {TouchableOpacity, View} from "react-native";
import CloseIcon from "../Svg/Close";
import {globalStyles} from "../../theme";

const ModalForm = ({
   open,
   form,
   type,
   animationType,
   transparent,
   headerTitle,
   handleCancel,
   handleSave,
   handleDelete,
   canDelete,
   background,
   saveButtonText,
   footerMessage,
   deleteButtonText,
   isLoading
}) => {
    return (
        <CustomModal open={open} transparent={transparent} type={type} animationType={animationType}>
            { background && <Image
                source={require('../../assets/bg-primary.png')}
                style={{
                    ...globalStyles.backgroundImage,
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
            />}
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={background ? styles.withBackground : undefined}>
                    <View style={[styles.modalHeader, background ? { marginTop: '5px'} : {}]}>
                        <Text style={styles.modalTitle}>{headerTitle}</Text>
                        <View>
                            <TouchableOpacity onPress={() => handleCancel()}>
                                <View style={styles.actionButton}>
                                    <CloseIcon color="#FFFFFF"/>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <Form
                        forms={form}
                        handleCancel={handleCancel}
                        handleDelete={handleDelete}
                        handleSave={handleSave}
                        footerMessage={footerMessage}
                        isLoading={isLoading}
                        saveButtonText={saveButtonText}
                        deleteButtonText={deleteButtonText}
                        canDelete={canDelete}
                        fullScreen={type === "fullScreen"}
                    />
                </View>
            </ScrollView>
        </CustomModal>
    );
};

export default ModalForm;
