import React, {useState} from 'react';

import { ModalForm } from "../../../../components";
import { useDropdown } from "../../../../hooks";
import { isNotificationSupported } from "../../../../utils";
import { gtag } from "../../../../service/GoogleAnalytics";

import WebAlert from "../../../../components/WebAlert";

import {ages, genres, knowledge} from "../../../../constants";

import useAuthStore from "../../../../store/auth-store";
import Firebase from "../../../../service/Firebase";
const SurveyAuth = () => {
    const [ survey, setSurvey ] = useState({firstname: "", lastname: "", genre: "", age: "", question: "", questionCustomValue: "",});

    const [ notificationPushModal, setNotificationPushModal ] = useState(false);
    const [ notificationLoader, setNotificationLoader ] = useState(false);

    const genreDropdown = useDropdown(genres);
    const ageDropdown = useDropdown(ages);
    const knowledgeDropdown = useDropdown(knowledge);

    const surveyAuth = useAuthStore(state => state.surveyAuth);
    const openSurvey = useAuthStore(state => state.openSurvey);
    const user = useAuthStore(state => state.user);

    const handleSave = async () => {
        if (isNotificationSupported()) {
            setNotificationPushModal(true);
            return;
        }

        await completeProcess();
    };

    const handleNotificationPush = async () => {
        setNotificationLoader(true);
        const tokenMessaging = await Firebase.requestPushNotificationPermission();

        if (user.uid && tokenMessaging) {
            await Firebase.setNotificationPushToken(user.uid, tokenMessaging);
            await completeProcess();

            setNotificationPushModal(false);
            setNotificationLoader(false);
            openSurvey(false);
        }
    };

    const completeProcess = async () => {
        await Firebase.completeUserSurvey(user.uid, {...survey, genre: genreDropdown.dropdownValue, age: ageDropdown.dropdownValue, question: knowledgeDropdown.dropdownValue});
        await Firebase.updateLastLogin(user.uid);

        gtag('event', 'login', {
            'method': 'email',
            'Survey': true,
            'user': JSON.stringify(user),
        });
    };

    const handleCancelNotificationPush = async () => {
        await completeProcess();

        setNotificationPushModal(false)
        openSurvey(false)
    }

    const form = [
        {
            key: 'firstname',
            label: 'Nome',
            type: 'textInput',
            handleChange: (value) => setSurvey({...survey, firstname: value}),
            placeholder: 'Aggiungi nome',
            value: survey.firstname
        },
        {
            key: 'lastname',
            label: 'Cognome',
            type: 'textInput',
            handleChange: (value) => setSurvey({...survey, lastname: value}),
            placeholder: 'Aggiungi cognome',
            value: survey.lastname
        },
        {
            key: "genre",
            label: "Genere",
            type: "dropdown",
            placeholder: "Genere",
            dropdownOpen: genreDropdown.dropdownOpen,
            setIsDropdownOpen: genreDropdown.setIsDropdownOpen,
            dropdownValue: genreDropdown.dropdownValue,
            setDropdownValue: genreDropdown.setDropdownValue,
            items: genreDropdown.items,
            setItems: genreDropdown.setItems,
        },
        {
            key: "age",
            label: "Età",
            type: "dropdown",
            placeholder: "Età",
            dropdownOpen: ageDropdown.dropdownOpen,
            setIsDropdownOpen: ageDropdown.setIsDropdownOpen,
            dropdownValue: ageDropdown.dropdownValue,
            setDropdownValue: ageDropdown.setDropdownValue,
            items: ageDropdown.items,
            setItems: ageDropdown.setItems,
        },
        {
            key: "question",
            label: "Come hai scoperto RicordaMe?",
            type: "dropdown",
            placeholder: "Raccontaci come l'hai scoperto",
            dropdownOpen: knowledgeDropdown.dropdownOpen,
            setIsDropdownOpen: knowledgeDropdown.setIsDropdownOpen,
            dropdownValue: knowledgeDropdown.dropdownValue,
            setDropdownValue: knowledgeDropdown.setDropdownValue,
            items: knowledgeDropdown.items,
            setItems: knowledgeDropdown.setItems,
        },
        {
            key: 'custom',
            label: 'Altro',
            type: 'textInput',
            isVisible: knowledgeDropdown.dropdownValue === 'altro',
            handleChange: (value) => setSurvey({...survey, questionCustomValue: value}),
            placeholder: 'Altro',
            value: survey.questionCustomValue
        },
    ];

    return (
        <>
            <ModalForm
                open={surveyAuth}
                form={form}
                type={"fullScreen"}
                headerTitle={"Compila il questionario"}
                transparent={false}
                canDelete={false}
                animationType={"slide"}
                saveButtonText={"Salva"}
                handleCancel={() => openSurvey(false)}
                handleSave={handleSave}
            >
            </ModalForm>
            {
                notificationPushModal && (
                    <WebAlert
                        open={notificationPushModal}
                        setOpen={setNotificationPushModal}
                        title={"Notifiche"}
                        alertText={"Vuoi attivare le notifiche push?"}
                        handleConfirm={handleNotificationPush}
                        confirmText={"Attiva"}
                        handleCancel={handleCancelNotificationPush}
                        loading={notificationLoader}
                    />
                )
            }
        </>
    );
};

export default SurveyAuth;
