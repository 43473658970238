import { create } from 'zustand';
import { createJSONStorage, persist } from "zustand/middleware";
import { UserState } from "../interfaces/store";
import { User } from 'firebase/auth';

import { gtag } from "../service/GoogleAnalytics";
import Firebase from "../service/Firebase";

const useAuthStore = create(persist<UserState>(
    (set) => ({
        loading: false,
        loggedIn: false,
        surveyAuth: false,
        user: null,
        setLoading: (newValue) => {

        },
        setUser: (user) => set({ user}),
        openSurvey: (newValue) => set({ surveyAuth: newValue }),
        logout: async () => {
            await Firebase.logout();

            // @ts-ignore
            gtag('event', 'logout', {
                'event_category': 'engagement',
                'event_label': 'logout'
            });
        },
        restoreSession: () => {
            Firebase.auth.onAuthStateChanged(async (user: User | null) => {
                if (user) {
                    set({
                        user: user,
                    });
                } else {
                    set({
                        user: null,
                    });
                }
            })
        }
    }),
    {
        name: 'auth-storage',
        storage: createJSONStorage(() => sessionStorage),
    },
),);

export default useAuthStore;
