import { StyleSheet } from 'react-native';
import {colors, fonts} from '../../../../theme';

export const styles= StyleSheet.create({
    title: {
        fontFamily: 'Fira Sans',
        fontWeight: '400',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontSize: '18px',
        textAlign: 'center',
        marginBottom: '10xp',
        color: colors.primary
    },
    subtitle: {
        color: colors.black,
        fontFamily: 'Fira Sans',
        fontWeight: '400',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontSize: '14px',
        marginVertical: '5px',
    },
    formInput: {
        backgroundColor: 'white',
        height: '40px',
        width:'261px',
        overflow: 'hidden',
        marginTop: 10,
        borderRadius: '10px',
        border: '1px solid #5F33E1',
        paddingLeft: 16,
    },
    button: {
        width: '100%',
        borderRadius: 12,
        padding: 12,
        marginTop: '42px',
        backgroundColor: colors.primary
    },
    buttonTitle: {
        color: colors.white,
        fontFamily: 'Fira Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        textAlign: 'center'
    },
    modal: {
        width: '300px',
        height: '238px',
        backgroundColor: colors.white,
        flexShrink: 0,
        boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px',
        paddingHorizontal: '20px',
        paddingVertical: '20px',
    }
});

