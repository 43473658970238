import Svg, { Path, Rect, Circle } from "react-native-svg";

const StatusLate = ({}) => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <Rect width="20" height="20" rx="6" fill="#FF3B30"/>
            <Path
                d="M8.70096 4.25C9.27831 3.25 10.7217 3.25 11.299 4.25L16.4952 13.25C17.0725 14.25 16.3509 15.5 15.1962 15.5H4.80385C3.64915 15.5 2.92746 14.25 3.50481 13.25L8.70096 4.25Z"
                stroke="#8B0700"/>
            <Rect x="9" y="6" width="2" height="5" rx="1" fill="#8B0700"/>
            <Circle cx="10" cy="13" r="1" fill="#8B0700"/>
        </Svg>
    );
};

export default StatusLate;
