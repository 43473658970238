import Svg, { Path, G } from "react-native-svg";
import React from "react";


const FunnelIcon = () => {
    return (
        <Svg width="40" height="40" viewBox="0 0 24 30" x="0px" y="0px">
            <G>
                <Path
                    d="M21,2H3A1,1,0,0,0,2,3V6a1,1,0,0,0,.21.61L9,15.34V19a1,1,0,0,0,.55.89l4,2A1,1,0,0,0,14,22a1,1,0,0,0,.53-.15A1,1,0,0,0,15,21V15.34l6.79-8.73A1,1,0,0,0,22,6V3A1,1,0,0,0,21,2ZM20,5.66l-6.79,8.73A1,1,0,0,0,13,15v4.38l-2-1V15a1,1,0,0,0-.21-.61L4,5.66V4H20Z"
                />
            </G>
        </Svg>
    );
};

export default FunnelIcon;
