import { StyleSheet } from 'react-native'
import { colors, fonts } from '../../../../theme'

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.white,
        paddingTop: 30,
        paddingHorizontal: 24,
        alignItems: 'center',
    },
    screenTitle:{
        fontSize: fonts.md,
        fontWeight: fonts.bold,
        marginBottom: 30,
    },
    textBanner:{
        fontSize: fonts.xs,
        fontWeight: fonts.light,
        color: colors.textLight,
        marginHorizontal: 10,
    },
    confirmButton: {
        backgroundColor: colors.done,
        marginTop: 40,
        height: 48,
        width: '100%',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
})