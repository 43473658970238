import { StyleSheet } from 'react-native'
import { colors, fonts } from '../../../../theme'

export const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        /*alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: colors.text,
        borderRadius: 12,
        paddingVertical: 15,
        paddingHorizontal: 24*/
    },
    noContentContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noContentText: {
      fontFamily: fonts.firaSans,
      fontSize: fonts.sm,
      color: colors.black,
      marginBottom: '12px'
    },
    openButton: {
        marginBottom: 20
    },
    title: {
        color: colors.primary,
        fontFamily: fonts.firaSans,
        fontSize: fonts.sm,
        marginBottom: 8,
    },
    label: {
        color: colors.primary,
        fontFamily: fonts.firaSans,
        fontSize: fonts.xxs,
        marginBottom: 8,
    },
    // Modal styles
    fullScreen: {
        backgroundColor: colors.backgroundLight,
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '80%',
        padding: 24,
        elevation: 5
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 8,
        marginBottom: 4,
    },
    modalTitle: {
        color: colors.text,
        fontFamily: fonts.semiBold,
        fontSize: fonts.sm,
        marginBottom: 12
    },
    modalBody: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    modalFooter: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 24,
        borderTopWidth: 1,
        borderTopColor: colors.text
    },
    modalCancelBtnText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.primary
    },
    modalSaveBtnText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.primary
    },
    modalDeleteBtnText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.critical
    },
    //Card styles
    titleCard: {
        fontFamily: fonts.firaSans,
        fontSize: '36px',
        color: colors.primary,
        fontStyle: 'normal',
        lineHeight: 'normal',
    },
    roundedBox: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        borderStyle: 'solid',
        borderWidth: 1,
        height: 25,
        width: 25,
        backgroundColor: colors.backgroundLight,
        borderColor: colors.white
    },
    addBtn: {
        borderRadius: '10px',
        height: '40px',
        width: '100%',
        backgroundColor: colors.backgroundLight,
        border: '1px solid #1D8136',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnText: {
        fontFamily: fonts.firaSans,
        fontSize: fonts.xs,
        color: colors.green
    },
})
