// React Native package imports
import React, {useEffect, useState} from "react";
import {Text, TouchableOpacity, View} from "react-native";

// Styles
import {styles} from "./styles";
import {colors} from "../../theme";
import CircleBox from "../Svg/CircleBox";
const Checkbox = ({value, onChange, valueSelected, label}) => {
    const [forceUpdate, setForceUpdate] = useState(false);

    useEffect(() => {
        setForceUpdate((prev) => !prev);
    }, [value]);

    return (
        <View key={forceUpdate ? Math.random() : "normalKey"}>
            <TouchableOpacity onPress={onChange}>
                <View style={styles.checkboxContainer}>
                    <CircleBox checked={value === valueSelected} style={{ marginRight: 10 }}/>
                    <Text style={{...styles.checkboxText, color: value === valueSelected ? colors.primary : colors.black }}>{label}</Text>
                </View>
            </TouchableOpacity>
        </View>
    )
}

export default Checkbox
