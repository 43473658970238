// React Native package imports
import React, { useState } from "react";
import { Text, TouchableOpacity, View, ScrollView } from "react-native";

// Custom component imports
import CustomModal from "../customModal";
import Checkbox from "../Checkbox";

// Styles
import {styles } from "./styles";
import WebAlert from "../WebAlert";
import CloseIcon from "../Svg/Close";
import Button from "../Button";

const ModalFilter = ({open, setOpen, data, filters, handleApply}) => {
    const [valueSelected, setValueSelected] = useState('');
    const [sortBy, setSortBy] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertInfo, setAlertInfo] = useState({});

    /*Loading*/
    const [isLoading, setIsLoading] = useState(false);
    const handleFilters = () => {
        setIsLoading(true);
        if ( sortBy === '' && valueSelected === '' ) {
            setAlertInfo({
                title: 'Warning',
                message: 'Please select a sort by option',
                type: 'warning'
            });
            setShowAlert(true);
            setIsLoading(false);
            return
        }
        const dataSorted = filters.map((filter) => {
            if ( filter.sortBy === sortBy ) {
                return filter.handle(data, valueSelected);
            }
        }).filter((item) => item !== undefined)[0];

        setIsLoading(false);
        handleApply(dataSorted);
    };

    const handleClearFilters = () => {
        setValueSelected('');
        setSortBy('');
        handleApply(data);
    }

    return (
        <>
            <CustomModal
                open={open}
                type="custom"
                transparent={true}
                animationType="slide"
                onRequestClose={() => setOpen(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalHeader}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={styles.modalTitle}> Filtra la lista </Text>
                        </View>

                        <TouchableOpacity onPress={() => setOpen(false)}>
                            <CloseIcon />
                        </TouchableOpacity>
                    </View>

                    <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', paddingVertical: '10px'}}>
                        <TouchableOpacity onPress={() => handleClearFilters()} style={styles.btnDelete}>
                            <Text style={styles.btnDeleteText}> Cancella tutti i filtri </Text>
                        </TouchableOpacity>
                    </View>

                    <ScrollView style={styles.modalBody}>
                        <View style={{ flex: '0 0 30%', maxHeight: '150px' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text style={styles.modalSubtitle}> Stato </Text>
                            </View>
                            <View style={{ marginLeft: 10, marginVertical: 15 }}>
                                {filters.map((filter, index) =>
                                    ( filter.group === 'status' &&
                                        (<Checkbox
                                            key={`${filter.sortBy}-${index}`}
                                            value={valueSelected}
                                            onChange={() => {
                                                setSortBy(filter.sortBy)
                                                setValueSelected(filter.sortBy)
                                            }}
                                            valueSelected={filter.sortBy}
                                            label={filter.label}
                                        />)
                                    )
                                )}
                            </View>
                        </View>
                        <View style={{ marginTop: filters[3].options.length > 5 ? '0px' : '55px' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text style={styles.modalSubtitle}> Pazienti </Text>
                            </View>
                            <View style={{ marginLeft: 10, marginVertical: 15 }}>
                                {filters.map((filter) =>
                                    ( filter.group === 'patients' &&
                                        filter.options.map((option, index) => (
                                            <Checkbox
                                                key={`${filter.group}-${index}`}
                                                value={valueSelected}
                                                onChange={() => {
                                                    setSortBy(filter.sortBy)
                                                    setValueSelected(option.value)
                                                }}
                                                valueSelected={option.value}
                                                label={option.label}
                                            />
                                        ))
                                    )
                                )}
                            </View>
                        </View>
                    </ScrollView>

                    <View style={styles.modalFooter}>
                        <View>
                            <Button title="APPLICA" handlePress={handleFilters} loading={isLoading}/>
                        </View>
                    </View>
                </View>
            </CustomModal>
            <WebAlert
                open={showAlert}
                setOpen={setShowAlert}
                title={alertInfo.title}
                alertText={alertInfo.message}
                error={true}
            />
        </>
    );
};

export default ModalFilter;
