//React components
import React from "react";
import {TouchableWithoutFeedback, TextInput, Text, View, ScrollView, Platform} from "react-native";

//Libs components
import DropDownPicker from "react-native-dropdown-picker";
import PhoneInput from "react-native-international-phone-number";


//Styles
import { styles } from "./styles";
import { colors } from "../../theme";
import Button from "../Button";

const Form = ({
    forms,
    handleCancel,
    handleDelete,
    handleSave,
    isLoading,
    footerMessage,
    saveButtonText,
    deleteButtonText,
    canDelete,
    fullScreen,
    }) => {

    return (
        <View style={[ fullScreen ? styles.fullContainer : ""]}>
            <View>
                    {
                        forms.map(
                            (form, index) => {
                                return (
                                    <View key={index} style={styles.formGroup}>
                                        {
                                            form.label && (form.isVisible !== undefined ? form.isVisible : true) && (
                                                <Text style={styles.formLabel}>{form.label}</Text>
                                            )
                                        }
                                        {
                                            form.subLabel && (form.isVisible !== undefined ? form.isVisible : true) && (
                                                <Text style={styles.formSubLabel}>{form.subLabel}</Text>
                                            )
                                        }
                                        {
                                            form.type === 'textInput' && (form.isVisible !== undefined ? form.isVisible : true) && (
                                                <TextInput
                                                    onChangeText={form.handleChange}
                                                    placeholder={form.placeholder}
                                                    value={form.value}
                                                    style={styles.formInput}
                                                    placeholderTextColor={colors.placeholderTextColor}
                                                />
                                            )
                                        }

                                        {
                                            form.type === 'textArea' && (form.isVisible !== undefined ? form.isVisible : true) && (
                                                <TextInput
                                                    onChangeText={form.handleChange}
                                                    placeholder={form.placeholder}
                                                    value={form.value}
                                                    style={styles.textArea}
                                                    multiline={true}
                                                    placeholderTextColor={colors.placeholderTextColor}
                                                />
                                            )
                                        }

                                        {
                                            form.type === 'phone' && (form.isVisible !== undefined ? form.isVisible : true) && (
                                                <PhoneInput
                                                    value={form.value}
                                                    defaultValue={form.defaultValue}
                                                    onChangePhoneNumber={form.handleChange}
                                                    defaultCountry="IT"
                                                    selectedCountry={form.selectedCountry}
                                                    onChangeSelectedCountry={form.handleChangeCountry}
                                                    phoneInputStyles={styles.phoneInput}
                                                />
                                            )
                                        }

                                        {
                                            form.type === "dropdown" && (form.isVisible !== undefined ? form.isVisible : true) && (
                                                <DropDownPicker
                                                    ListEmptyComponent={() => {
                                                        return (
                                                            <View style={styles.emptyDropdown}>
                                                                <Text style={styles.emptyDropdownText}>{
                                                                    form.emptyDropdownText ?? "Nessun elemento trovato"
                                                                }</Text>
                                                            </View>
                                                        )
                                                    }}
                                                    listMode={ Platform.OS === 'web' ? "MODAL" : "SCROLLVIEW"}
                                                    open={form.dropdownOpen}
                                                    setOpen={form.setIsDropdownOpen}
                                                    value={form.dropdownValue}
                                                    setValue={form.setDropdownValue}
                                                    onChangeValue={form.handleChange}
                                                    items={form.items}
                                                    setItems={form.setItems}
                                                    placeholder={form.placeholder}
                                                    style={styles.formInput}
                                                    textStyle={styles.dropdownText}
                                                    labelStyle={styles.dropdownLabel}
                                                    dropDownContainerStyle={styles.dropdownContainer}
                                                    placeholderStyle={styles.dropdownPlaceholder}
                                                    modalTitle="Select an item"
                                                    modalContentContainerStyle={{
                                                        backgroundColor: colors.cardBg,
                                                        borderWidth: 2,
                                                        borderColor: colors.cardBorder,
                                                        borderRadius: 10,
                                                        paddingVertical: 10,
                                                        paddingHorizontal: 20,
                                                        marginBottom: 8,
                                                        elevation: 5,
                                                        height: '60%',
                                                        width: '80%',
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        margin: "auto",
                                                    }}
                                                    searchable={true}
                                                    searchablePlaceholder="Search for an item"
                                                    modalProps={{
                                                        animationType: "slide",
                                                        transparent: true,
                                                    }}
                                                />
                                            )
                                        }

                                        {
                                            form.customComponent
                                        }
                                        {
                                            form.helperText && (form.isVisible !== undefined ? form.isVisible : true) && (
                                                <Text style={styles.helperText}>{form.helperText}</Text>
                                            )
                                        }
                                    </View>
                                )
                            }
                        )
                    }
            </View>

            <View style={styles.formActions}>
                { footerMessage &&
                    <Text style={{ textAlign: 'center', color: colors.red}}>
                        {footerMessage}
                    </Text>
                }
                <Button handlePress={handleSave} title="Salva" loading={isLoading}/>

                { canDelete &&
                    <TouchableWithoutFeedback onPress={handleDelete}>
                        <View
                            style={[
                                styles.formAction,
                                {marginTop: '10px'}
                            ]}
                        >
                            <Text
                                style={[
                                    styles.formActionText,
                                    styles.formActionTextSecondary,
                                ]}
                            >
                                {deleteButtonText ?? 'Elimina'}
                            </Text>
                        </View>
                    </TouchableWithoutFeedback>
                }
            </View>
        </View>
    )
};

export default Form;
