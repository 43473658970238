export const statusItems = [
    { label: "Critical", value: "critical" },
    { label: "High", value: "high" },
    { label: "Medium", value: "medium" },
    { label: "Low", value: "low" },
];

export const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
];
export const daysOfWeek = [
    { "id": 1, "day": "Lunedì", status: "pending"},
    { "id": 2, "day": "Martedì", status: "pending"},
    { "id": 3, "day": "Mercoledì", status: "pending"},
    { "id": 4, "day": "Giovedì", status: "pending"},
    { "id": 5, "day": "Venerdì", status: "pending"},
    { "id": 6, "day": "Sabato", status: "pending"},
    { "id": 0, "day": "Domenica", status: "pending"},
]
export const initialLetterOfDaysOfWeek = [
    { id: 1, initial: 'L'},
    { id: 2, initial: 'M'},
    { id: 3, initial: 'M' },
    { id: 4, initial: 'G'},
    { id: 5, initial: 'V'},
    { id: 6, initial: 'S'},
    { id: 0, initial: 'D'}
];

export const genres = [
    { label: "Maschile", value: "maschile" },
    { label: "Femminile", value: "femminile" },
    { label: "Non binario/a", value: "nonBinario" },
];

export const ages = [
    { label: "Minorenne", value: "minorenne" },
    { label: "100+", value: "100+" },
    { label: "95-99", value: "95-99" },
    { label: "90-94", value: "90-94" },
    { label: "85-89", value: "85-89" },
    { label: "80-84", value: "80-84" },
    { label: "75-79", value: "75-79" },
    { label: "70-74", value: "70-74" },
    { label: "65-69", value: "65-69" },
    { label: "60-64", value: "60-64" },
    { label: "55-59", value: "55-59" },
    { label: "50-54", value: "50-54" },
    { label: "45-49", value: "45-49" },
    { label: "40-44", value: "40-44" },
    { label: "35-39", value: "35-39" },
    { label: "30-34", value: "30-34" },
    { label: "25-29", value: "25-29" },
    { label: "18-24", value: "18-24" },
];

export const knowledge = [
    { label: "Fase di testing", value: "testing" },
    { label: "Consiglio di un amico", value: "amico" },
    { label: "Consiglio di un parente", value: "parente" },
    { label: "Altro", value: "altro" },
];

export const persona = [
    {label: "Nonna/nonno", value: "nonna / nonno"},
    {label: "Padre/madre", value: "padre / madre"},
    {label: "Figlia/figlio", value: "figlia / figlio"},
    {label: "Zio/zia", value: "zio / zia"},
    {label: "Cugina/cugino", value: "cugina / cugino"},
    {label: "Parente di secondo grado", value: "parente di secondo grado"},
    {label: "Vicina/vicino di casa", value: "vicina / vicino di casa"},
    {label: "Amico/amica", value: "amico / amica"},
    {label: "Assistito", value: "Assistito"},
    {label: "Altro", value: "altro"},
];


export const fonts = {
    'Lato-Black': require('../assets/fonts/Lato-Black.ttf'),
    'Lato-Bold': require('../assets/fonts/Lato-Bold.ttf'),
    'Lato-Regular': require('../assets/fonts/Lato-Regular.ttf'),
    'Raleway-Medium': require('../../assets/fonts/Raleway-Medium.ttf'),
    'Raleway-Regular': require('../../assets/fonts/Raleway-Regular.ttf'),
    'Raleway-Light': require('../../assets/fonts/Raleway-Light.ttf'),
    'Raleway-ExtraLight': require('../../assets/fonts/Raleway-ExtraLight.ttf'),
    'Raleway-Thin': require('../../assets/fonts/Raleway-Thin.ttf'),
    'TwemojiMozilla': require('../../node_modules/react-native-international-phone-number/lib/assets/fonts/TwemojiMozilla.woff2'),
    'Fira Sans': require('../../assets/fonts/Fira_Sans/FiraSans-Regular.ttf'),
}

export const initialStateTask = (user) => {
    return {
        description: "",
        status: "pending",
        attachments: [],
        reminders: [],
        patientID: "",
        caregiverID: String(user ? user.uid : ''),
        range: {
            startDate: null,
            endDate: null
        }
    };
}

export const initialStatePatient = (user) => {
    return {
        firstname: "",
        lastname: "",
        fullname: "",
        mobile: "",
        caregiverID: String(user ? user.uid : ''),
        age: "",
        genre: "",
        connection: "",
        otherConnection: "",
    };
}
