// React
import React, {useEffect, useRef, useState} from 'react'
import { ImageBackground, TouchableOpacity, View } from "react-native";

//store
import useStore from "../../store";
import useAuthStore from "../../store/auth-store";

//Firebase
import { where, query, collection, onSnapshot, getFirestore } from "firebase/firestore";

//components
import { Header } from "../../components";
import PatientList from "./components/PatientList";
import PatientModal from "./components/PatientModal";
import WebAlert from "../../components/WebAlert";

// styles
import { styles } from './styles';
import {AntDesign} from "@expo/vector-icons";
import {globalStyles} from "../../theme";

export default function PatientsScreen() {
    const user = useAuthStore(state => state.user);
    const patients = useStore(state => state.patients);
    const setPatients = useStore(state => state.setPatients);
    const flatListRef = useRef();

    const deletePatient = useStore(state => state.deletePatient);

    const [patientToEdit, setPatientToEdit] = useState(null);
    const [deleteId, setDeleteId] = useState("");

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(collection(getFirestore(), "patients"),
                where("caregiverID", "==", user.uid),
                where('deletedAt', '==', null))
            ,
            (snapshot) => {
                const patients = snapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
                setPatients(patients);
            });

        return () => unsubscribe();
    }, []);

    const triggerEdit = (patient) => {
        setPatientToEdit(patient);
        setEditModalVisible(true);
    }

    const handleDelete = (id) => {
        setShowDeleteAlert(true);
        setDeleteId(id);
    }

    const handleConfirmDelete = async () => {
        deletePatient(deleteId);
        setShowDeleteAlert(false);
    };

    const resetPatientToEdit = () => {
        setPatientToEdit(null);
    };

    return(
        <ImageBackground
            source={require('../../assets/bg-primary.png')}
            style={globalStyles.backgroundImage}
        >
            <View style={styles.container}>
                <Header
                    title="Assistiti"
                    subtitle="Aggiungi, elimina o modifica"
                    modalVisible={addModalVisible}
                    setModalVisible={setAddModalVisible}
                    headerButton
                />
                <PatientList
                    patients={patients}
                    flatListRef={flatListRef}
                    triggerEdit={triggerEdit}
                    handleDelete={handleDelete}
                />
                <PatientModal
                    open={addModalVisible}
                    setOpen={setAddModalVisible}
                    flatListRef={flatListRef}
                    patients={patients}
                />
                {
                    editModalVisible &&  (
                        <PatientModal
                            open={editModalVisible}
                            setOpen={setEditModalVisible}
                            flatListRef={flatListRef}
                            patients={patients}
                            patientToEdit={patientToEdit}
                            resetPatientToEdit={resetPatientToEdit}
                            edit={true}
                        />
                    )
                }
                {
                    showDeleteAlert && (
                    <WebAlert
                        open={showDeleteAlert}
                        setOpen={setShowDeleteAlert}
                        title="Eliminazione assistita"
                        alertText="Sei sicuro di voler cancellare questo assistiti?"
                        handleConfirm={handleConfirmDelete}
                    />
                )}
                <TouchableOpacity
                    onPress={() => setAddModalVisible(!addModalVisible)}
                    style={styles.buttonPlus}
                >
                    <AntDesign name="plus" size={25} color="white" />
                </TouchableOpacity>
            </View>
        </ImageBackground>
    )
}
