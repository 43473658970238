//React Modules
import React, { useState, useRef, useCallback } from "react";
import { View, ImageBackground, TouchableOpacity } from "react-native";

//Store
import useStore from "../../store";
import useAuthStore from "../../store/auth-store";

//Firebase
import { where, query, collection, onSnapshot, getFirestore } from "firebase/firestore";

//Components
import {Header} from "../../components";
import  TaskList  from "./components/TaskList";
import TaskModal from "./components/TaskModal";
import SurveyAuth from "../AuthScreen/components/surveyAuth";

//Styles
import { styles } from "./styles";
import {globalStyles} from "../../theme";
import {AntDesign} from "@expo/vector-icons";
import {useFocusEffect} from "@react-navigation/native";

const TodoScreen = () => {
  const user = useAuthStore((state) => state.user);
  const setTasks = useStore((state) => state.setTasks);

  const [taskToEdit, setTaskToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);

  useFocusEffect(useCallback(() => {
    const unsubscribe = onSnapshot(
        query(collection(getFirestore(), "tasks"),
            where("caregiverID", "==", user.uid),
            where('deletedAt', '==', null)
        ),
        (snapshot) => {
            const tasks = snapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
            setTasks(tasks);
    });

    return () => unsubscribe();
  }, []));

  const triggerEditTask = (task) => {
    setTaskToEdit(task);
    setShowModal(true);
  };

  const flatListRef = useRef();

  return (
    <ImageBackground
        source={require('../../assets/bg-primary.png')}
        style={globalStyles.backgroundImage}
    >
      <View style={styles.container}>
        <Header
          title="Promemoria"
          subtitle="Aggiungi, elimina o modifica"
          modalVisible={showModal}
          setModalVisible={setShowModal}
          headerButton
          filterButton
          setFilterVisible={setFilterVisible}
        />
        <TaskList
          flatListRef={flatListRef}
          triggerEditTask={triggerEditTask}
          filterVisible={filterVisible}
          setFilterVisible={setFilterVisible}
        />
        {showModal && (
            <TaskModal
                open={showModal}
                setOpen={setShowModal}
                taskToEdit={taskToEdit}
                setTaskToEdit={setTaskToEdit}
                resetTaskToEdit={() => setTaskToEdit(null)}
            />
        )}
        <TouchableOpacity
            onPress={() => setShowModal(!showModal)}
            style={styles.buttonPlus}
        >
          <AntDesign name="plus" size={25} color="white" />
        </TouchableOpacity>
      </View>
      <SurveyAuth/>
    </ImageBackground>
  );
};

export default TodoScreen;
