const TelegramOff = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" id="telegram-off" height="24px">
            <path fill="grey" d="m12 24c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-23c-6.065 0-11 4.935-11 11s4.935 11 11 11 11-4.935 11-11-4.935-11-11-11z"></path>
            <path fill="grey" d="m9.5 18c-.064 0-.13-.012-.191-.038-.187-.077-.309-.26-.309-.462v-3c0-.133.053-.26.146-.354l2.36-2.36-3.759 2.147c-.161.092-.359.087-.517-.013l-3-1.913c-.159-.101-.247-.283-.229-.47.019-.187.141-.348.315-.417l13-5.087c.171-.064.36-.035.5.08.141.115.207.297.175.476l-2 11c-.028.155-.128.288-.269.358s-.307.07-.447 0l-3.678-1.839-1.745 1.745c-.094.096-.222.147-.352.147zm2-3c.076 0 .152.017.224.053l3.403 1.702 1.721-9.463-11.25 4.402 1.917 1.222 6.737-3.85c.218-.123.494-.068.646.13.152.199.133.48-.044.657l-4.854 4.854v1.586l1.146-1.146c.096-.097.224-.147.354-.147z"></path>
        </svg>
    );
}

export default TelegramOff;
