import React, {useRef, useState} from 'react';
import { Text, TextInput, TouchableOpacity, View } from 'react-native'
import styles from './styles'
import SurveyAuth from "../surveyAuth";
import Button from "../../../../components/Button";
import {gtag} from "../../../../service/GoogleAnalytics";

import Firebase from "../../../../service/Firebase";

import useAuthStore from "../../../../store/auth-store";
import useStore from "../../../../store";

const EmailAuth = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    /* Store */
    const setFirstTimeLogin = useAuthStore(state => state.openSurvey);

    /* Focus */
    const [focusEmail, setFocusEmail] = useState(false);
    const [focusPassword, setFocusPassword] = useState(false);

    /* Loading */
    const [btnLoading, setBtnLoading] = useState(false);

    const onLoginPress = async () => {
        try {
            setBtnLoading(true)
            const uid = await Firebase.login(email, password);
            const userData = await Firebase.userData(uid);

            if (!userData) {
                alert("The user does not exist.")
                setBtnLoading(false);
                return;
            }

            if (!await Firebase.checkSurveyExists(uid)) {
                setBtnLoading(false);
                setFirstTimeLogin(true);
                return;
            }

            /*Update last login*/
            await Firebase.updateLastLogin(uid);

            /** Google Analytics */
            gtag('event', 'login', {
                'method': 'email',
                'user': JSON.stringify(userData.data()),
            });

            setBtnLoading(false);
        } catch (err) {
            setBtnLoading(false);
            alert(err.message);
        }
    }


    return (
        <View>
            <View>
                <Text style={styles.label}>E-mail</Text>
                <TextInput
                    style={[styles.input, focusEmail && styles.focusedInput]}
                    placeholderTextColor="#aaa"
                    placeholder="esempio@dominio.it"
                    onChangeText={(text) => setEmail(text)}
                    onFocus={() => setFocusEmail(true)}
                    value={email}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                />
            </View>
            <View>
                <Text style={styles.label}>Password</Text>
                <TextInput
                    placeholderTextColor="#aaa"
                    secureTextEntry
                    placeholder="﹡﹡﹡﹡﹡﹡﹡﹡"
                    onChangeText={(text) => setPassword(text)}
                    style={[styles.input, focusPassword && styles.focusedInput]}
                    onFocus={() => setFocusPassword(true)}
                    value={password}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                />
            </View>
            <Button
                title="ACCEDI"
                handlePress={onLoginPress}
                loading={btnLoading}
                customStyles={{ marginHorizontal: '20px', width: '268px' }}
            />
        </View>
    )
}

export default EmailAuth;
