import { StyleSheet } from "react-native";
import {colors, fonts} from "./index";

export const globalStyles = StyleSheet.create({
    input: {
        backgroundColor: 'white',
        height: '40px',
        width:'261px',
        overflow: 'hidden',
        borderRadius: '10px',
        border: '1px solid #5F33E1',
        paddingLeft: 16,
    },
    backgroundImage: {
        flex: 1,
        resizeMode: 'cover',
        alignItems: 'center',
        width: '100%',
    },
});
