import Svg, { Circle, G, Path } from "react-native-svg";
import {colors} from "../../theme";


const UserIcon = ({ focused }) => {
    return (
        <>
            <Svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"
                 style={{position: 'relative'}}>
                <Circle cx="19" cy="19" r="19" fill={focused ? colors.primary : colors.primaryLight}
                        stroke={focused ? colors.primary : ""}/>
            </Svg>

            <Svg x="0px" y="0px" viewBox="0 0 20 23" style={{position: 'absolute'}}>
                <G>
                    <Path fill={colors.white} d="M10,10c-5,0-9,4-9,9c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1C19,14,15,10,10,10z M3.1,18c0.5-3.4,3.4-6,6.9-6s6.4,2.6,6.9,6   H3.1z"/>
                    <Path fill={colors.white} d="M10,9c2.5,0,4.5-2,4.5-4.5C14.5,2,12.5,0,10,0C7.5,0,5.5,2,5.5,4.5C5.5,7,7.5,9,10,9z M10,2c1.4,0,2.5,1.1,2.5,2.5   S11.4,7,10,7S7.5,5.9,7.5,4.5S8.6,2,10,2z"/>
                </G>
            </Svg>
        </>
    );
};

export default UserIcon;
