import React from "react";

import {ActivityIndicator, StyleSheet, View} from "react-native";
import {StatusBar} from "expo-status-bar";

import {colors} from "../../theme";


const Loading = () => {
    return (
        <>
            <StatusBar style="dark" />
            <View style={styles.loaderContainer}>
                <ActivityIndicator size="large" color={colors.primary} />
            </View>
        </>
    )
};

const styles = StyleSheet.create({
    loaderContainer: {
        flex: 1,
        backgroundColor: colors.backgroundLight,
        justifyContent: 'center',
        alignItems: 'center'
    }
})


export default Loading;
