import React, { useState} from "react";
import {DatePickerModal} from "react-native-paper-dates";
import { PaperProvider, DefaultTheme } from 'react-native-paper';
import {Button, Text, TouchableOpacity, View} from "react-native";
import {colors} from "../../../../theme";
import {styles} from "../ReminderManager/styles";
import Card from "../../../../components/Card/Card";

const theme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        primary: colors.primary,
        primaryContainer: colors.primaryLight,
        secondary: colors.secondary,
        background: colors.primaryLight
    },
};
const DatePicker = ({ range, setRange}) => {
    //const [range, setRange] = useState({ startDate: undefined, endDate: undefined });
    const [open, setOpen] = useState(false);

    const onDismiss = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirm = React.useCallback(
        ({ startDate, endDate }) => {
            startDate.setHours(0,0,0,0);
            endDate.setHours(0,0,0,0);
            setOpen(false);
            setRange({ startDate, endDate });
        },
        [setOpen, setRange]
    );

    return (
        <View style={{ justifyContent: 'center', flex: 1, alignItems: 'center', position: 'relative' }}>
            {
                range.startDate && range.endDate ? (
                    <View style={{ marginTop: '20px', width: '100%'}}>
                        <Card>
                            <View section="header">
                                <Text style={[styles.title, { fontWeight: 'bold'}]}>
                                    Periodo selezionato {' '}
                                </Text>
                            </View>

                            <View section="body">
                                <Text style={[styles.label]}>
                                    {
                                        new Intl.DateTimeFormat('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).format(range.startDate)
                                    } {' '}
                                    - {' '}
                                    {
                                        new Intl.DateTimeFormat('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).format(range.endDate)
                                    }
                                </Text>
                            </View>
                        </Card>
                    </View>
                ) : (
                    <Text style={[styles.label, { fontWeight: 'bold', position: 'absolute', zIndex: 10, top: '-22px', left: '25%'}]}>Sempre valido (data finale non impostata)</Text>
                )
            }
            <TouchableOpacity onPress={() => setOpen(true)} style={styles.addBtn}>
                <Text style={styles.btnText}>Imposta periodo di validità</Text>
            </TouchableOpacity>
            <PaperProvider theme={theme}>
                <DatePickerModal
                    locale="it"
                    saveLabel={"Salva"}
                    label={"Seleziona periodo di validità"}
                    startLabel={"Data inizio"}
                    endLabel={"Data fine"}
                    mode="range"
                    visible={open}
                    onDismiss={onDismiss}
                    startDate={range.startDate}
                    endDate={range.endDate}
                    onConfirm={onConfirm}
                />
            </PaperProvider>
        </View>
    );
};

export default DatePicker;

