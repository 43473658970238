// React modules
import { useEffect } from 'react'

//Libs
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack';

//Store
import useAuthStore from "../store/auth-store";

//components
import AuthScreen from "../screens/AuthScreen";
import TabsNavigator from "./tabs";
import useStore from "../store";

const Stack = createStackNavigator();

const AppNavigator = () => {
  const user = useAuthStore(state => state.user);
  const restoreSession = useAuthStore(state => state.restoreSession);
  const fetchPatients = useStore(state => state.fetchPatients);

  useEffect(() => {
    restoreSession();
  }, [])

  useEffect(() => {
    if (user) {
      fetchPatients(user.uid);
    }
  }, [user]);

  return (
    <NavigationContainer theme={{
        colors: {
            background: 'transparent',
        },
    }}>
      <Stack.Navigator screenOptions={{}}>
        { user !== null ? (
          <>
            <Stack.Screen name="Home" component={TabsNavigator} options={{ headerShown: false }}/>
          </>
        ) : (
          <>
            <Stack.Screen name="Login" component={AuthScreen} options={{ headerShown: false }}/>
          </>
        )
        }
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default AppNavigator
