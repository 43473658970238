import React, {useEffect} from 'react'

import { StyleSheet, ImageBackground } from 'react-native'
import { StatusBar } from 'expo-status-bar'
import { useFonts } from 'expo-font'
import { gtag } from './service/GoogleAnalytics'


import AppNavigator from './navigation'
import { colors } from './theme'
import Loading from "./components/Loading";
import useStore from "./store";

const App = () => {
    const GOOGLE_TAG = process.env.EXPO_PUBLIC_GOOGLE_TAG;
    const setFonts = useStore(state => state.setFonts);
    const loaded = setFonts();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG}`;
        script.async = true;
        document.body.appendChild(script);
        window.dataLayer = window.dataLayer || [];

        gtag('js', new Date());
        gtag('config', `${GOOGLE_TAG}`);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

  if (!loaded) {
    return (
      <Loading/>
    )
  }

  return (
    <ImageBackground
        source={require('./assets/bg-primary.png')}
        style={{ flex: 1 }}
    >
      <StatusBar style="dark" />
      <AppNavigator />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    backgroundColor: colors.backgroundLight,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default App
