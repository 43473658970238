//React modules
import React, { useState } from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';

//Styles
import { colors } from "../../theme";
import { styles } from "./styles";
const CheckboxList = ({ data, selectedValues, onSelectionChange, itemView }) => {
    const [selectedItems, setSelectedItems] = useState(selectedValues);

    const toggleCheckbox = (item) => {
        const updatedSelection = [...selectedItems];
        const index = updatedSelection.findIndex(element => element.id === item.id);

        if (index !== -1) {
            updatedSelection.splice(index, 1);
        } else {
            updatedSelection.push(item);
        }

        setSelectedItems(updatedSelection);
        onSelectionChange(updatedSelection);
    };

    const renderItem = ({ index, item }) => {
        const isSelected = selectedItems.some(element => element.id === item.id);
        return (
            <TouchableOpacity onPress={() => toggleCheckbox(item)}>
                <View style={{ ...styles.roundBox, backgroundColor: isSelected ? colors.primary : colors.gray, borderColor: isSelected ? colors.white : colors.gray }}>
                    { itemView(item.id) }
                </View>
            </TouchableOpacity>
        )
    };

    return (
        <FlatList
            horizontal={true}
            data={data}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
        />
    );
};

export default CheckboxList;
