import Svg, {Path, Rect} from "react-native-svg";

const ArrowRight = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <Rect width="20" height="20" rx="6" fill="#D9D9D9"/>
            <Path
                d="M5 9C4.44772 9 4 9.44772 4 10C4 10.5523 4.44772 11 5 11V9ZM15.7071 10.7071C16.0976 10.3166 16.0976 9.68342 15.7071 9.29289L9.34315 2.92893C8.95262 2.53841 8.31946 2.53841 7.92893 2.92893C7.53841 3.31946 7.53841 3.95262 7.92893 4.34315L13.5858 10L7.92893 15.6569C7.53841 16.0474 7.53841 16.6805 7.92893 17.0711C8.31946 17.4616 8.95262 17.4616 9.34315 17.0711L15.7071 10.7071ZM5 11H15V9H5V11Z"
                fill="#3D3D3D"/>
        </Svg>
    )
};

export default ArrowRight;
