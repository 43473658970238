import { StyleSheet } from 'react-native'
import { colors, fonts } from '../../theme'

export const styles = StyleSheet.create({
    //Checkbox styles
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 12,
    },
    checkboxText: {
        fontFamily: fonts.firaSans,
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontSize: fonts.xs,
    },
    checkbox: {
        marginRight: 10,
        borderRadius: 50,
        borderStyle: 'solid',
        borderWidth: 1,
        height: 25,
        width: 25
    },
})
