// React modules
import React, {useEffect, useState} from "react";
import {Text, TouchableOpacity, View} from "react-native";

// Libs
import { PaperProvider, DefaultTheme } from 'react-native-paper';
import {TimePickerModal} from "react-native-paper-dates";
import {AntDesign} from "@expo/vector-icons";
import uuid from 'react-native-uuid';

//Components
import CustomModal from "../../../../components/customModal";
import CheckboxList from "../../../../components/CheckboxList";

//Styles
import {styles} from "../ReminderManager/styles";
import {colors, fonts} from "../../../../theme";

//Constants
import {daysOfWeek, initialLetterOfDaysOfWeek} from "../../../../constants";
import {getDayOfWeek} from "../../../../utils";

const theme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        primary: colors.primary,
        primaryContainer: colors.primaryLight,
        secondary: colors.secondary,
        secondaryContainer: colors.primaryLight,
        surfaceVariant: colors.primaryLight,
        onPrimaryContainer: colors.black,
        onSurface: colors.black
    },
};
const DateTimePickerModal = ({ open, setOpen, saveData, item, setItem, onDelete }) => {
    const today = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Rome"}));
    const hours = today.getHours() < 10 ? '0'+today.getHours() : today.getHours();
    const minutes = today.getMinutes() < 10 ? '0'+today.getMinutes() : today.getMinutes();

    const [showTimePicker, setShowTimePicker] = useState(false);
    const [editTime, setEditTime] = useState({ hours, minutes  });

    const initialReminder = {
        id: uuid.v4(),
        time: `${hours}:${minutes}:00`,
        displayTime: `${ hours }:${minutes}`,
        days: [{ id: today.getDay(), day: getDayOfWeek( today ), status: "pending" }],
        hours: today.getHours(),
        minutes: today.getMinutes(),
        active: true
    }

    const [ reminder, setReminder ] = useState(item ?? initialReminder);

    useEffect(() => {
        if (item) {
            setReminder({...item});
        } else {
            setReminder(initialReminder)
        }

        return () => {
            setReminder(initialReminder);
            setItem(null);
        }
    }, []);
    const onChangeDays = (selectedValues) => {
        reminder.days = selectedValues;
        setReminder({...reminder});
    };

    const onConfirm = ({ hours, minutes }) => {
        if (minutes < 10) minutes = `0${minutes}`;
        if (hours < 10) hours = `0${hours}`;
        const time = `${hours}:${minutes}:00`;
        const displayTime = `${hours}:${minutes}`;
        reminder.time = time;
        reminder.displayTime = displayTime;
        reminder.hours = hours;
        reminder.minutes = minutes;
        setReminder({...reminder});
        setShowTimePicker(false);
    };

    const checkboxView = (id) => {
        const item = initialLetterOfDaysOfWeek.find((item) => item.id === id);
        return (
            <Text style={{ color: '#FFF'}}>{item.initial}</Text>
        )
    };

    return (
        <>
            <CustomModal open={open} transparent={true} animationType="slide" onRequestClose={()=>setOpen(false)}>
                <View style={styles.modalHeader}>
                    <TouchableOpacity onPress={() => setOpen(false)}>
                        <Text style={styles.modalCancelBtnText}> Annulla </Text>
                    </TouchableOpacity>

                    <Text style={styles.modalTitle}> { item ? "Modifica ricorrenza" : "Aggiungi ricorrenza"} </Text>

                    <TouchableOpacity onPress={() => saveData(reminder)}>
                        <Text style={styles.modalSaveBtnText}> Salva </Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.modalBody}>
                    <Text style={{ color: colors.placeholderTextColor }}> Ora </Text>
                    <TouchableOpacity onPress={() => {
                        setShowTimePicker(true)
                        if (item) {
                            setEditTime({
                                hours: Array.from(item.hours)[0] === '0' ? Array.from(item.hours)[1] : item.hours,
                                minutes: Array.from(item.minutes)[0] === '0' ? Array.from(item.minutes)[1] : item.minutes
                            })
                        }
                    }}>
                        <Text style={{ fontFamily: fonts.firaSans, fontSize: 70, color: colors.primary }}> { reminder.displayTime } </Text>
                    </TouchableOpacity>

                    <Text style={{ color: colors.placeholderTextColor, marginTop: 50, marginBottom: 20 }}> Personalizza la ricorrenza </Text>

                    <View style={{ backgroundColor: colors.cardBg,
                        borderWidth: 2,
                        borderColor: colors.cardBorder,
                        borderRadius: 10,
                        paddingVertical: 15,
                        paddingHorizontal: 20,
                        marginBottom: 20,
                        elevation: 5  }}>
                        <View section="header">
                            <Text style={{ fontFamily: fonts.semiBold, fontSize: fonts.sm, marginBottom: 20, marginLeft: 10 }}>Repeat</Text>
                        </View>

                        <View section="body" style={{ height: 35 }}>
                            <CheckboxList
                                data={daysOfWeek}
                                selectedValues={reminder.days}
                                itemView={checkboxView}
                                onSelectionChange={(selectedValues) => onChangeDays(selectedValues)}
                            />
                        </View>
                    </View>

                    { item && <TouchableOpacity onPress={() => onDelete(reminder.id)}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Text style={styles.modalDeleteBtnText}> Elimina </Text>
                            <AntDesign name="delete" size={15} color={colors.critical}/>
                        </View>
                    </TouchableOpacity> }

                    {showTimePicker &&
                        (
                            <PaperProvider theme={theme}>
                                <TimePickerModal
                                    visible={showTimePicker}
                                    onDismiss={() => setShowTimePicker(false)}
                                    onConfirm={onConfirm}
                                    hours={editTime.hours}
                                    minutes={editTime.minutes}
                                    use24HourClock={true}
                                />
                            </PaperProvider>
                        )}
                </View>
            </CustomModal>
        </>
    );
}

export default DateTimePickerModal;
