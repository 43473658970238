import {useState} from "react";
import {View, TouchableOpacity, Text, TextInput} from "react-native";

//Services
import Firebase from "../../../../service/Firebase";

//Components
import CustomModal from "../../../../components/customModal";
import WebAlert from "../../../../components/WebAlert";
import CloseIcon from "../../../../components/Svg/Close";
import Button from "../../../../components/Button";

//Styles
import { styles } from "./styles";

const ChangePasswordModal = ({ open, setOpen }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [alertModal, setAlertModal] = useState(false);
    const [alerInfo, setAlerInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const changePassword = () => {
        setLoading(true);
        if (newPassword === confirmPassword) {
            Firebase.auth.currentUser.updatePassword(newPassword).then(() => {
                setOpen(false);
                setAlerInfo({
                    title: 'Success',
                    text: 'Password changed successfully'
                });
                setLoading(false);
                setAlertModal(true);
            }).catch((error) => {
                setAlerInfo({
                    title: 'Error',
                    text: error.message,
                    error: true
                });
                setLoading(false)
                setAlertModal(true);
            });
        } else {
            setAlerInfo({
                title: 'Error',
                text: 'Passwords do not match',
                error: true
            });
            setLoading(false);
            setAlertModal(true);
        }
    };

    return (
        <>
            <CustomModal open={open} transparent={true} animationType="slide">
                <View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.title}>Change Password</Text>
                        <TouchableOpacity onPress={() => setOpen(false)}>
                            <CloseIcon/>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.form}>
                        <View style={styles.formGroup}>
                            <Text style={styles.label}>Please enter your new password</Text>
                            <TextInput
                                style={styles.formInput}
                                placeholderTextColor="#aaa"
                                secureTextEntry
                                placeholder="New password"
                                onChangeText={(text) => setNewPassword(text)}
                                value={newPassword}
                                underlineColorAndroid="transparent"
                                autoCapitalize="none"
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text style={styles.label}>Please confirm your new password</Text>
                            <TextInput
                                style={styles.formInput}
                                placeholderTextColor="#aaa"
                                secureTextEntry
                                placeholder="Confirm new password"
                                onChangeText={(text) => setConfirmPassword(text)}
                                value={confirmPassword}
                                underlineColorAndroid="transparent"
                                autoCapitalize="none"
                            />
                        </View>
                    </View>
                    <View>
                        <Button handlePress={changePassword} loading={loading} title="Modifica della password"/>
                        <TouchableOpacity
                            style={styles.cancelButton}
                            onPress={() => setOpen(false)}>
                            <Text style={styles.buttonTitle}>Annullamento</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </CustomModal>
            {
                alertModal && alerInfo &&
                <WebAlert open={alertModal} setOpen={setAlertModal} title={alerInfo.title} alertText={alerInfo.text} error={alerInfo.error}/>
            }
        </>
    );
};

export default ChangePasswordModal;
