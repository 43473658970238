import React from 'react'
import { View, TouchableWithoutFeedback, Modal, Keyboard } from 'react-native'
import { styles } from './styles'

const CustomModal = ({ open, children, onRequestClose, animationType, transparent, type }) => {
  return (
    <Modal visible={open} transparent={transparent} animationType={animationType} onRequestClose={onRequestClose}>
      <TouchableWithoutFeedback>
        <View style={styles.modalContainer}>
            { type == "custom" ? children
                : <View style={[type == "fullScreen" ? styles.fullScreen : styles.modal]}>{children}</View>
            }
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

export default CustomModal
