import Svg, { Path, Rect } from "react-native-svg";

const StatusProgress = ({}) => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <Rect width="20" height="20" rx="6" fill="#FFAA33"/>
            <Rect x="5.5" y="4.5" width="9" height="11" rx="1.5" stroke="#663C00"/>
            <Path d="M8 7.5H12M8 10H12M8 12.5H12" stroke="#663C00" strokeLinecap="round"/>
            <Path d="M8 4V5M12 4V5" stroke="#663C00" strokeLinecap="round"/>
        </Svg>
    );
};

export default StatusProgress;
