export const fonts = {
  black: 'Lato-Black',
  bold: 'bold',
  semiBold: 'Lato-Regular',
  medium: 'Raleway-Medium',
  regular: 'Raleway-Regular',
  light: 'Raleway-Light',
  extraLight: 'Raleway-ExtraLight',
  thin: 'Raleway-Thin',
  firaSans: 'Fira Sans',

  xl: 44,
  lg: 26,
  md: 22,
  sm: 18,
  xs: 14,
  xxs: 12,
  iconSize:22
}
