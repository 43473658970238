import { StyleSheet } from 'react-native'
import {colors, fonts} from '../../../../theme'

export const styles = StyleSheet.create({
    listContainer: {
        flex: 1
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    noContentContainer: {
        flex: 0.8,
        alignItems: 'center',
        justifyContent: 'center'
    },
    noContentImg: {
        tintColor: colors.yellow,
        width: 36,
        height: 36,
        marginBottom: 12
    },
    noContentText: {
        color: colors.text,
        fontFamily: fonts.medium,
        fontSize: fonts.sm
    },
    title: {
        color: colors.primary,
        fontFamily: fonts.firaSans,
        fontSize: fonts.sm,
        lineHeight: 'normal',
    },
    // Filter styles
    filters: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: 12
    },

    // Priority styles
    priority: {
        color: colors.text,
        fontFamily: fonts.bold,
        fontSize: fonts.xs,
        textTransform: 'uppercase',
    },
    critical: {
        color: colors.critical
    },
    high: {
        color: colors.high
    },
    medium: {
        color: colors.medium
    },
    low: {
        color: colors.low
    },

    // Status styles
    completed: {
        backgroundColor: colors.completedBg,
        color: colors.completedColor,
        fontFamily: fonts.bold,
        fontSize: fonts.xs,
        textTransform: 'capitalize',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 6,
    },
    pending: {
        backgroundColor: colors.pendingBg,
        color: colors.pendingColor,
        fontFamily: fonts.bold,
        fontSize: fonts.xs,
        textTransform: 'capitalize',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 6,
    },
    missed: {
        backgroundColor: colors.missedBg,
        color: colors.missedColor,
        fontFamily: fonts.bold,
        fontSize: fonts.xs,
        textTransform: 'capitalize',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 6,
    },

    //Card styles
    section: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cardFooter: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    reminderText: {
        color: colors.gray,
        fontFamily: fonts.firaSans,
        fontSize: fonts.xxs,
        marginLeft: 9,
    },
    actionButton: {
        marginLeft: 16
    },
    actionIcon: {
        width: 16,
        height: 16,
        resizeMode: 'contain',
        tintColor:colors.shadow
    },

    // Dropdown
    formInput: {
        backgroundColor: colors.backgroundLight,
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm,
        padding: 12,
        borderRadius: 12,
        borderWidth: 1,
        borderColor: colors.text
    },
    dropdownText: {
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm
    },
    dropdownLabel: {
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm
    },
    dropdownContainer: {
        backgroundColor: colors.backgroundLight,
        borderColor: colors.text
    },
    dropdownPlaceholder: {
        color: colors.textLight,
        fontFamily: fonts.regular,
        fontSize: fonts.sm
    },
})
