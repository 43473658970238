// React modules
import React from "react";
import { FlatList, Text, View, TouchableOpacity} from 'react-native'

//components
import Card from "../../../../components/Card/Card";

//styles
import { styles }  from './styles'
import TelegramOn from "../../../../components/Svg/Telegram-on";
import TelegramOff from "../../../../components/Svg/Telegram-off";

const PatientList = ({ patients, flatListRef, triggerEdit, handleDelete}) => {
    const renderItem = ({ item }) => (
        <TouchableOpacity onPress={() => triggerEdit(item)}>
            <Card>
                <View section="header">
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}>
                        <Text style={styles.patientTitle}>{`${item.firstname} ${item.lastname}`}</Text>
                        {
                            item.chatId ? (
                                <TelegramOn/>
                            ) : (
                                <TelegramOff/>
                            )
                        }
                    </View>
                </View>

                <View section="body">
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={styles.patientsubTitle}>{item.mobile}</Text>
                    </View>
                </View>
            </Card>
        </TouchableOpacity>
    )

    return (
        <View style={styles.listContainer}>
            {
                patients.length === 0 ? (
                    <View style={styles.noContentContainer}>
                        <Text style={styles.noContentText}>No patients</Text>
                    </View>
                ) : (
                    <FlatList
                        ref={flatListRef}
                        data={patients}
                        renderItem={renderItem}
                        showsVerticalScrollIndicator={false}
                    />
                )
            }
        </View>
    )
}

export default PatientList;
