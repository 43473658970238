import { StyleSheet } from "react-native";
import { colors, fonts } from "../../theme";

export const styles = StyleSheet.create({
    fullContainer: {
        height: '100%',
        gap: '20px',
    },
    formGroup: {
        marginBottom: 10
    },
    emptyDropdown: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyDropdownText: {
        fontSize: fonts.xs,
        color: colors.gray,
        fontFamily: fonts.firaSans,
        fontWeight: fonts.bold,
        textAlign: 'center',
    },
    formLabel: {
        fontSize: fonts.xs,
        color: colors.primary,
        fontFamily: fonts.firaSans,
        fontWeight: fonts.bold,
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5,
    },
    formSubLabel: {
        fontSize: fonts.xs,
        color: colors.textOpacity,
        fontWeight: fonts.firaSans,
        marginLeft: '10px',
    },
    helperText: {
        fontSize: fonts.xs,
        color: colors.textOpacity2x,
        fontFamily: fonts.firaSans,
        fontWeight: fonts.bold,
        marginHorizontal: 10,
        textDecorationLine: 'underline',
        marginTop: 5,
        marginLeft: '10px',
    },
    formInput: {
        border: `1px solid ${colors.primary}`,
        borderColor: colors.primary,
        backgroundColor: 'white',
        height: '40px',
        overflow: 'hidden',
        borderRadius: 12,
        paddingLeft: 16,
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm,
        padding: 12,
    },
    textArea: {
        border: `1px solid ${colors.primary}`,
        height: '144px',
        textAlignVertical: 'top',
        backgroundColor: colors.backgroundLight,
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm,
        padding: 12,
        borderRadius: 10,
    },
    formActions: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 12,
        marginBottom: 10,
        zIndex: -1,
    },
    formAction: {
        width: '100%',
        borderRadius: 12,
        padding: 12,
        cursor: 'pointer',
        border: `1px solid ${colors.red}`,
    },
    primaryButton: {
        backgroundColor: colors.primary,
        width: '100%',
        height: '40px',
    },
    secondaryButton: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: colors.primary
    },
    formActionText: {
        color: colors.white,
        fontFamily: fonts.firaSans,
        fontSize: fonts.xs,
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    formActionTextSecondary: {
        color: colors.red,
        fontFamily: fonts.firaSans,
        fontSize: fonts.xs,
        textAlign: 'center'
    },
    dropdownText: {
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm
    },
    dropdownLabel: {
        color: colors.text,
        fontFamily: fonts.regular,
        fontSize: fonts.sm
    },
    dropdownContainer: {
        backgroundColor: colors.backgroundLight,
        borderColor: colors.primary,
    },
    dropdownPlaceholder: {
        color: colors.textLight,
        fontFamily: fonts.regular,
        fontSize: fonts.sm
    },
    phoneInput: {
        container: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: colors.primary,
        },
        flagContainer: {
            borderTopLeftRadius: 7,
            borderBottomLeftRadius: 7,
            backgroundColor: colors.white,
            justifyContent: 'center',
        },
        callingCode: {
            fontSize: '16px',
            fontFamily: fonts.firaSans,
            color: colors.primaryLight,
        },
    }
})
