import { StyleSheet } from 'react-native'
import { colors, fonts } from '../../theme'

export const styles = StyleSheet.create({
    container: {
        marginTop: 12,
        flexDirection: 'column',
    },
    modalContainer: {
        backgroundColor: colors.white,
        width: '330px',
        height: 'auto',
        maxHeight: '500px',
        padding: 24,
        justifyContent: 'space-between',
        borderRadius: '10px',
        boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, 0.10)',
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    modalTitle: {
        fontFamily: fonts.firaSans,
        fontWeight: '400',
        fontSize: fonts.lg,
        color: colors.black,
        fontStyle: 'normal',
        lineHeight: 'normal',
    },
    modalSubtitle: {
        fontFamily: fonts.firaSans,
        fontWeight: '400',
        fontSize: fonts.md,
        color: colors.primary,
        fontStyle: 'normal',
        lineHeight: 'normal',
    },
    modalBody: {
        height: '100%',
    },
    modalFooter: {
        height: 'auto',
        flexDirection: 'column',
        /*justifyContent: 'center',
        alignItems: 'center',*/
    },
    modalCancelBtnText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.primary
    },
    modalSaveBtnText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.primary
    },
    modalDeleteBtnText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.critical
    },
    //Card styles
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        paddingVertical: 12,
        width: '268px',
        height: '40px',
    },
    btnDelete: {
        width: '100%',
        borderRadius: 12,
        padding: 12,
        border: `1px solid ${colors.red}`,
    },
    btnDeleteText: {
        color: colors.red,
        fontFamily: fonts.firaSans,
        fontSize: fonts.xs,
        textAlign: 'center'
    },

    //Checkbox styles
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        //justifyContent: 'space-between',
        marginBottom: 12,
    },
    checkboxText: {
        fontFamily: fonts.regular,
        fontSize: 16,
        color: colors.text
    },
    checkbox: {
        marginRight: 10,
        borderRadius: 50,
        borderStyle: 'solid',
        borderWidth: 1,
        height: 25,
        width: 25
    },
})
