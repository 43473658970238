import { StyleSheet } from 'react-native'
import { colors, fonts } from '../../../../theme'

export const styles = StyleSheet.create({
    listContainer: {
        flex: 1
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    noContentContainer: {
        flex: 0.8,
        alignItems: 'center',
        justifyContent: 'center'
    },
    noContentImg: {
        tintColor: colors.yellow,
        width: 36,
        height: 36,
        marginBottom: 12
    },
    noContentText: {
        color: colors.text,
        fontFamily: fonts.medium,
        fontSize: fonts.sm
    },
    patientTitle: {
        color: colors.primary,
        fontFamily: fonts.firaSans,
        fontSize: fonts.sm,
        textTransform: 'capitalize',
    },
    patientsubTitle: {
        color: colors.text,
        fontFamily: fonts.firaSans,
        fontSize: fonts.xxs,
    },
    patientMobile: {
        color: colors.text,
        fontFamily: fonts.semiBold,
        fontSize: fonts.xs,
    }
})
