import { StyleSheet } from 'react-native'
import {colors} from "../../theme";

export default StyleSheet.create({
    container: {
        width: '300px',
        borderRadius: '20px',
        backgroundColor: 'white',
        boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, 0.25)',
        marginTop: '90px',
        paddingVertical: '30px',
    },
    backgroundImage: {
        flex: 1,
        resizeMode: 'cover',
        alignItems: 'center',
    },
    logo: {
        display: 'block',
        height: 40,
        width: 180,
        alignSelf: "center",
        marginVertical: 20
    },
    input: {
        backgroundColor: 'white',
        height: 48,
        overflow: 'hidden',
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
        marginLeft: 30,
        marginRight: 30,
        paddingLeft: 16,

    },
    button: {
        backgroundColor: '#788eec',
        marginLeft: 30,
        marginRight: 30,
        marginTop: 20,
        height: 48,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    signInPhoneButton: {
        backgroundColor: colors.secondary,
        marginHorizontal: '20px',
        marginTop: '10px',
        height: '40px',
        width: '268px',
        borderRadius: '10px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    signInEmailButton: {
        backgroundColor: '#BD4437',
        marginLeft: 30,
        marginRight: 30,
        marginTop: 20,
        height: 48,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonTitle: {
        color: colors.tertiary,
        fontSize: '14px',
        fontFamily: 'Fira Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
    },
    footerView: {
        flex: 1,
        alignItems: 'center',
        marginTop: 20
    },
    footerText: {
        fontSize: '12px',
        color: 'black',
        fontFamily: 'Fira Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
    },
    footerLink: {
        color: colors.primary,
        fontWeight: "400",
        fontSize: '12px',
        fontFamily: 'Fira Sans',
        fontStyle: 'normal',
        lineHeight: 'normal',
    }
})
