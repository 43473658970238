export const colors = {
  primary: '#0088cc', //#5F33E1
  primaryLight: '#8FB8CC', //#D5D5FF
  primaryDark: '#282828',
  secondary: '#FFAA33',
  tertiary: '#663C00',


  backgroundLight: '#FAFFFD',
  backgroundDark: '#3D3D3D',
  backgroundGray: '#D9D9D9',
  placeholderTextColor:'#bbb',
  text: '#323031',
  textLight: '#D5D5D5',
  textOpacity: '#3D3D3D',
  textOpacity2x: '#434343',

  shadow: '#303030',
  modalBackground: 'rgba(44, 42, 43, 0.437)',

  cardBg: '#FFF',
  cardBorder: '#EEEEEE',

  critical: '#e64545',
  high: '#FA824C',
  medium: '#F7B731',
  low: '#4CD97B',
  done: '#05820f',
  pending: '#f50606',

  white: '#FFFFFF',
  black: '#000000',
  red: '#E1564F',
  redLight: '#fe9790',
  green: '#1D8136',
  yellow: '#FFC857',
  gray: '#C8C8C8',
  blue: '#2D6DED',

  gradientOne:['rgba(9, 70, 144, 0.6)','rgba(26, 122, 96, 0.6)'],
  gradientTwo:['#F6EA41','#F048C6'],
  gradientThree:['#BB73E0','#FF8DDB'],
  gradientFour:['#0CCDA3','#C1FCD3'],

  completedBg: '#003E31',
  completedColor: '#4CBB9A',
  pendingBg: '#5D2A17',
  pendingColor: '#ECAF36',
  missedBg: '#751B1D',
  missedColor: '#E38E8F'
}
