import React from "react";
import {
    Text,
    View,
    TouchableWithoutFeedback,
    TouchableOpacity, Image,
} from "react-native";

import {AntDesign, Entypo, MaterialCommunityIcons} from "@expo/vector-icons";
import Svg, { Circle, Rect, Path } from "react-native-svg";

import { styles } from "./styles";
import { colors } from "../../theme";
import FunnelIcon from "../Svg/FunnelIcon";

const Header = ({
  title,
  subtitle,
  modalVisible,
  setModalVisible,
  headerButton,
  filterButton,
  setFilterVisible
}) => {


  return (
      <>
          <Image
              style={styles.logo}
              source={require('../../../assets/icon.png')}
          />
          <View style={styles.header}>
              <View>
                  <View>
                      <Text style={styles.headerHeading}>{title}</Text>
                  </View>
                  <Text style={styles.headerSubheading}>{subtitle}</Text>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                  {filterButton && (
                      <TouchableOpacity onPress={() => setFilterVisible(true)} style={styles.buttonStyle}>
                          <FunnelIcon />
                      </TouchableOpacity>
                  )}
              </View>
          </View>
      </>
  );
};

export default Header;
